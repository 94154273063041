import styled from "styled-components";
import { Box } from "@mui/material";

export const CustomBox = styled(Box)`
  .header {
    position: sticky;
    width: 100%;
  }
  .main-container {
    margin-top: 10px;
    max-height: 80vh;
    /*overflow: auto;*/
  }
  .title {
    color: #2756f3;
    font-size: 28px;
    font-weight: bold;
  }
  .btn-fab {
    background: rgb(0, 166, 172);
    color: white;
    position: absolute;
    bottom: 60px;
    right: 60px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
`;

export const DividerCustom = styled.hr`
  width: 100%;
  border-color: #d3d6dd !important;
  border-width: 1.5px !important;
  border-top: 1.5px solid #d3d6dd;
  margin: 16px 0;
  padding: 0;
`;
