import styled from "styled-components";
import Icon from "../Icon";
import {ReactComponent as Logo} from "./../../assets/Logo1_.svg";

export const Container = styled.div`
  width: 80px;
  background-color: #496fef;
  height: 100vh;
  position: fixed;
  box-shadow: 10px 0px 7px -6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 3;
  transition: width 0.2s;

  &:hover {
    width: 300px;
    overflow: visible;
  }

  .icon-menu {
    padding-right: 30px;
    padding-left: 25px;
  }
`;

export const ContainerMenu = styled.div`
  height: calc(100% - 160px);
  width: 100%;
  overflow: hidden; /* Eliminamos todo scroll en el contenedor principal */
  display: flex;
  flex-direction: column; /* Asegura que los elementos se apilen en columna */
`;

export const ContainerMenuItems = styled.div`
  flex: 1; /* Permite que el contenedor de desplazamiento ocupe el espacio disponible */
  overflow-y: auto;
  overflow-x: hidden;
  
  /* Estilos personalizados para la barra de desplazamiento */
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Color más claro y sutil */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2); /* Más transparente */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4); /* Oscurecimiento en hover */
  }
`;

export const Item = styled.div`
  position: ${(props) => (props.$isFinal === true ? "absolute" : "")};
  bottom: ${(props) => (props.$isFinal === true ? "0px" : "")};
`;

export const IconStyle = styled(Icon)`
  width: 35px;
  height: 35px;
  filter: brightness(0) invert(1); /* Cambia el color del icono a blanco */

  &:hover {
    filter: none; /* Cambia el color del icono al color original en el hover */
  }

  svg {
    fill: #ffffff; /* Asegúrate de que el relleno SVG sea blanco por defecto */
  }

  &:hover svg {
    fill: #496fef; /* Cambia el color del relleno SVG al color original en el hover */
  }
`;

export const Title = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;

    span {
      color: #496fef;
    }

    img{
      filter: none;
    }

    svg{
      filter: none;
    }
  }

  span {
    color: ${(p) => (p.$visible ? "#ffffff" : "#ffffff")};
    font-weight: ${(p) => (p.$visible ? "600" : "500")};
  }
`;

export const ContainerSubMenu = styled.div`
  visibility: ${(p) => (p.$visible ? "visible" : "hidden")};
  opacity: ${(p) => (p.$visible ? "1" : "0")};
  position: ${(p) => (p.$visible ? "relative" : "absolute")};
  transition: visibility 0s, opacity 0.5s linear;
`;

export const SubMenu = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  
`;

export const SubTitle = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;
  padding-left: 25px;
  

  &:hover {
    background-color: #ffffff;

    .selectedMenu {
      color: #496fef;
    }

    span {
      color: #496fef;
    }

    svg{
      filter: none;
    }

    img {
      filter: none;
    }
  }
  
  span {
    color: ${(p) => (p.$visible ? "#496fef" : "#ffffff")};
    width: 100%;
  }
`;

export const ContainerSecondSubMenu = styled.div`
  visibility: ${(p) => (p.$visible ? "visible" : "hidden")};
  opacity: ${(p) => (p.$visible ? "1" : "0")};
  position: ${(p) => (p.$visible ? "relative" : "absolute")};
  transition: visibility 0s, opacity 0.5s linear;
`;

export const SecondSubTitle = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 40px;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-image: linear-gradient(to top, #00B3B0 2px, #effefc 2px);

    .selectedMenu {
      color: #00B3B0;
    }
  }
  
  span {
    width: 100%;
  }
`;

export const ButtonClose = styled.button`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;
  border: none;
  background-color: #496fef;
  position: absolute;
  bottom: 0;
  left: 0;

  svg, span {
    color: #ffffff;
  }

  &:hover {
    background-color: #ffffff;
    
    svg, span {
      color: #496fef;
    }

    img{
      filter: none;
    }
  }

  &.selected {
    svg, span {
      color: #496fef;
    }

    &:hover {
      background-color: #ffffff;
    }
  }

  span {
    width: 75px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.3px;
  }
`;

export const IconStyleLogOut = styled(Icon)`
  width: 35px;
  height: 35px;
  text-align: center;
  margin-right: 30px;
  margin-left: 25px;
  filter: brightness(0) invert(1); /* Cambia el color del icono a blanco */

  &:hover {
    filter: none; /* Cambia el color del icono al color original en el hover */
  }
`;

export const ContainerSkeleton = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ImgStyle = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px 0;
  vertical-align: middle;
  border-style: none;
`;

export const ContainerLogo = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: auto;
`;

export const StyledLogo = styled(Logo)`
  width: 80%;
  height: auto;
`;

export const ContainerPerfil = styled.div`
  background-color: #496fef;
  display: flex;
  height: 100px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  color: white;
`;

export const ProfileName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserName = styled.span`
  font-weight: bold;
`;

export const UserTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  color: #ef9549;
  border-radius: 50%;
  font-size: 15px;
  margin-top: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
`;

export const ButtonPerfil = styled.button`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  padding: 10px 0;
  cursor: pointer;
  border: none;
  background-color: #496fef;
  bottom: 0;
  left: 0;
  padding-left: 10px;

  svg, span {
    color: #ffffff;
  }

  span {
    width: 200px;
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.3px;
  }
`;
