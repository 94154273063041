import "./styles.css";

import Document from "@tiptap/extension-document";
import Gapcursor from "@tiptap/extension-gapcursor";
import Paragraph from "@tiptap/extension-paragraph";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Text from "@tiptap/extension-text";
import OrderedList from '@tiptap/extension-ordered-list';
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import History from "@tiptap/extension-history";
import TextAlign from "@tiptap/extension-text-align";
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import BulletList from '@tiptap/extension-bullet-list';
import ListItem from '@tiptap/extension-list-item';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import Color from "@tiptap/extension-color";
import CharacterCount from '@tiptap/extension-character-count';
import FontSize from "tiptap-extension-font-size";
import { EditorContent, useEditor } from "@tiptap/react";
import React from "react";
import { Toolbar } from "./plugins/Toolbar";
import { Grid } from "@mui/material";
import { EditorStyle } from "./styles";

export const Editor = ({ disabled, importJSON, setJSON, limit }) => {
  const editor = useEditor({
    extensions: [
      Document,
      History,
      Paragraph,
      OrderedList,
      BulletList,
      ListItem,
      TextStyle,
      Color,
      FontFamily,
      FontSize,
      Text,
      Bold,
      Italic,
      Strike,
      Heading,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Gapcursor,
      Table.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      Highlight.configure({ multicolor: true }),
      CharacterCount.configure({
        limit: limit,
      }),
    ],
    content: "",
    onUpdate({ editor }) {
      if (!disabled) {
        setJSON(editor.getJSON());
      }
    },
    onCreate({ editor }) {
      editor.setEditable(!disabled);
      editor.commands.setContent(importJSON ?? "");
    }
  });

  if (!editor) {
    return null;
  }

  const percentage = editor
    ? Math.round((100 / limit) * editor.storage.characterCount.characters())
    : 0

  return (
    <EditorStyle container mt={3} mb={3}>
      {!disabled && <Grid className="toolbar" item xs={12}>
        <Toolbar editor={editor} />
      </Grid>}
      <Grid item xs={12}>
        <EditorContent className="editorContent" editor={editor} />

        {!disabled &&
          <div className={`character-count ${editor.storage.characterCount.characters() === limit ? 'character-count--warning' : ''}`}>
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
            >
              <circle
                r="10"
                cx="10"
                cy="10"
                fill="#e9ecef"
              />
              <circle
                r="5"
                cx="10"
                cy="10"
                fill="transparent"
                stroke="currentColor"
                strokeWidth="10"
                strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
                transform="rotate(-90) translate(-20)"
              />
              <circle
                r="6"
                cx="10"
                cy="10"
                fill="white"
              />
            </svg>

            {editor.storage.characterCount.characters()} / {limit} characters
            <br />
            {editor.storage.characterCount.words()} words
          </div>}
      </Grid>
    </EditorStyle>
  );
};
