import { useNavigate, useParams } from "react-router-dom";
import { Grid, IconButton } from "@mui/material"
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import DataTable from "../../components/DataTable/DataTable"
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import { useEffect, useState } from "react";
import { StyledIcon, StyledIconMui } from "./components/NewBattery/ConfigurationGarantty/Garanty.Styled";
import { Delete } from "@mui/icons-material";
import { IconDetail } from "../../components/Icon";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal"
import { useDeleteBatteries, useGetBatteries } from "./api/BatteryApi";

export const BatteryContainer = () => {
  const history = useNavigate();
  const { id } = useParams();
  const [modelTable, setModelTable] = useState([]);
  const [showConfimationModal, setShowConfirmationModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectBattery, setSelectBattery] = useState(null);
  const batteriesQuery = useGetBatteries();
  const deleteBattery = useDeleteBatteries();

  const columns = [
    { id: "id", label: "ID" },
    { id: "brand", label: "Marca" },
    { id: "model", label: "Modelo" },
    { id: "warranty", label: "Garantía" },
    { id: "actions", label: "Acciones" },
  ];

  const handleDeleteBattery = async () => {
    await deleteBattery.mutateAsync(selectBattery.id);
    setShowConfirmationModal(false)
    setTimeout(() => {
      setShowConfirmModal(true);
    }, 500);
    batteriesQuery.refetch();
  }

  useEffect(() => {
    if (batteriesQuery.isSuccess && batteriesQuery.data) {
      const auxData = Array.isArray(batteriesQuery.data) ? batteriesQuery.data : [];
      handleSetterModelsTables(auxData);
    }
  }, [batteriesQuery.isSuccess, batteriesQuery.data]);

  const handleSetterModelsTables = (dataTable) => {
    if (!Array.isArray(dataTable)) {
      return;
    }

    const models = dataTable.map((battery) => {
      return {
        id: battery.id,
        brand: battery.brand,
        model: battery.model,
        warranty: battery.warranty,
        actions: (
          <Grid container justifyContent="center" alignItems={"center"}>
            <Grid item>
              <IconButton
                onClick={() => {
                  history(`detalle/${battery?.id}`);
                }}
              >
                <StyledIcon>
                  <IconDetail />
                </StyledIcon>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                onClick={() => {
                  setSelectBattery(JSON.parse(JSON.stringify(battery)));
                  setShowConfirmationModal(true);
                }}
              >
                <StyledIconMui bgColor={"#d44240"}>
                  <Delete />
                </StyledIconMui>
              </IconButton>
            </Grid>
          </Grid>
        ),
      };
    });
    setModelTable(models);
  };

  return (
    <CustomTemplate title="Catálogo de Baterías">
      <Grid container>
        <Grid item xs={12}>
          <DataTable
            columns={columns}
            data={modelTable}
            adjustColumn={["actions"]}
            filterable
            customBtn={
              <Grid item xs={6} container justifyContent={"right"}>
                <Grid item>
                  <CustomButton
                    variant="outlined"
                    onClick={() => history(`nuevo`)}
                  >
                    Nueva batería
                  </CustomButton>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={showConfimationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSucces={() => handleDeleteBattery()}
        isAvailableCancel={true}
        isAvailableAcept={true}
        message={`¿Está seguro que desea eliminar la batería "${selectBattery?.brand}-${selectBattery?.model}"?`}
        status={"warning"}
      />
      <ConfirmationModal
        open={showConfirmModal}
        onSucces={() => {
          setShowConfirmModal(false);
        }}
        isAvailableAcept={true}
        message={"La batería se eliminó con éxito."}
      />
    </CustomTemplate>
  );
}