import { Grid, IconButton } from "@mui/material"
import DataTable from "../../../../components/DataTable/DataTable"
import { columnsNodes } from "../../interface/Data";
import { TitleSection } from "../../../../components/Base/TitleSection"
import { useContext, useEffect, useState } from "react";
import { ProjectionsContext } from "../../../../utils/context/projectionsContext";
import { IconButtonMui, IconButtonNormal } from "../../../../components/Base/Buttons/Buttons";
import { IconDetail } from "../../../../components/Icon";
import { Timeline } from "@mui/icons-material";
import { StyledIcon } from "../../../../components/Base/Buttons/Buttons.Styled";
import ModalTarifNodeDetail from "../ModalTarifNodeDetail/ModalTarifNodeDetail";
import NodeActivityLogModal from "../NodeActivityLogModal/NodeActivityLogModal";
import { NumberFormat } from "../../../../utils/utils";

export const ListNodes = () => {
    const columns = columnsNodes;
    const { state, setSelectNode, setSelectedNodeDetail, disabled } = useContext(ProjectionsContext);
    const [modelTable, setModelTable] = useState([]);
    const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
    const [isOpenModalNodeActivity, setIsOpenModalNodeActivity] = useState(false);

    const handleSetterModelsTable = (dataTable) => {
        const models = dataTable.map((node) => {
            return {
                id: node.nodeId,
                node: node.node,
                load: NumberFormat(node.load, "es-MX"),
                unload: NumberFormat(node.unload, "es-MX"),
                loadInterval: node.loadInterval,
                downloadTime: node.unloadInterval,
                difference: NumberFormat(node.difference, "es-MX"),
                perDay: NumberFormat(node.perDay, "es-MX"),
                perMonth: NumberFormat(node.perMonth, "es-MX"),
                perYear: NumberFormat(node.perYear, "es-MX"),
                hoursList: node.hoursList,
                actions: (
                    <Grid container justifyContent="center" alignItems={"center"}>
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    const nodes = JSON.parse(JSON.stringify(node))
                                    setSelectedNodeDetail(nodes);
                                    setIsOpenModalDetail(true);
                                }}>
                                <StyledIcon bgColor={"#fd932f"}>
                                    <IconDetail />
                                </StyledIcon>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => {
                                const nodes = JSON.parse(JSON.stringify(node))
                                setSelectedNodeDetail(nodes);
                                setIsOpenModalNodeActivity(true);
                            }}>
                                <IconButtonMui bgColor={"#2756F3"}>
                                    <Timeline />
                                </IconButtonMui>
                            </IconButton>
                        </Grid>
                    </Grid>
                ),
            }
        });
        setModelTable(models);
    }

    useEffect(() => {
        if (state && Array.isArray(state?.listNodes)) {
            const auxData = JSON.parse(JSON.stringify(state?.listNodes ?? []));
            handleSetterModelsTable(auxData);
        }
    }, [state?.listNodes])

    return (
        <Grid container marginTop={3}>
            <TitleSection title={"Listado de nodos"} />
            <Grid item xs={12}>
                <DataTable
                    columns={columns}
                    data={modelTable}
                    adjustColumn={["actions"]}
                    filterable
                />
            </Grid>
            {isOpenModalDetail &&
                <ModalTarifNodeDetail isOpen={isOpenModalDetail} onClose={() => setIsOpenModalDetail(false)} />}
            
            {isOpenModalNodeActivity &&
                <NodeActivityLogModal isOpen={isOpenModalNodeActivity} onClose={() => setIsOpenModalNodeActivity(false)} />
            }

        </Grid>
    )
}