import { useNavigate } from "react-router-dom";
import { Grid, IconButton } from "@mui/material"
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import DataTable from "../../components/DataTable/DataTable"
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import { useEffect, useState } from "react";
import { Delete } from "@mui/icons-material";
import { IconDetail } from "../../components/Icon";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal"
import { columnsProjections } from "./interface/Data";
import { IconButtonMui, IconButtonNormal } from "../../components/Base/Buttons/Buttons";
import { useDeleteProjection, useGetProjections } from "./api/Projectionsapi";
import { useGetExchangeRate } from "../ExchangeRate/api/ExchangeRateApi";
import { COMPONENTS, NumberFormat } from "../../utils/utils";

export const ProjectionsContainer = () => {
    const history = useNavigate();
    const [modelTable, setModelTable] = useState([]);
    const [showConfimationConfig, setShowConfirmationConfig] = useState(false);
    const [selectProjection, setSelectProjection] = useState(null);
    const projectionsQuery = useGetProjections();
    const columns = columnsProjections;
    const exchangeRate = useGetExchangeRate();
    const deleteProjections = useDeleteProjection();
    const [open, setOpen] = useState({ open: false, component: null, message: null, status: null });

    const handleDeleteProjection = async () => {
        await deleteProjections.mutateAsync(selectProjection.projectionId);
        setOpen({ open: true, component: COMPONENTS.SUCCESS, message: `Se elimino correctamente la proyección`, status: "success" });
    }

    const handleCreateProjection = async () => {
        if (exchangeRate.isSuccess && exchangeRate.data?.exchangeRate) {
            setShowConfirmationConfig(false);
            history(`nuevo`);
        } else {
            setShowConfirmationConfig(true);
        }
    }

    useEffect(() => {
        if (projectionsQuery.isSuccess && projectionsQuery.data) {
            const auxData = Array.isArray(projectionsQuery.data) ? projectionsQuery.data : [];
            handleSetterModelsTable(auxData);
        }
    }, [projectionsQuery.isSuccess, projectionsQuery.data]);

    const handleSetterModelsTable = (dataTable) => {
        if (!Array.isArray(dataTable)) {
            return;
        }

        const models = dataTable.map((projection) => {
            return {
                projectionId: projection.projectionId,
                projectionName: projection.projectionName,
                megaWatts: NumberFormat(projection.megaWatts, "es-MX"),
                dischargeTime: projection.dischargeTime,
                actions: (
                    <Grid container justifyContent="center" alignItems={"center"}>
                        <Grid item>
                            <IconButton
                                onClick={() => {
                                    history(`detalle/${projection?.projectionId}`)
                                }}>
                                <IconButtonNormal>
                                    <IconDetail />
                                </IconButtonNormal>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => {
                                setSelectProjection(JSON.parse(JSON.stringify(projection)));
                                setOpen({ open: true, component: COMPONENTS.DELETE, message: `¿Está seguro que desea eliminar la proyección de inversión "${projection?.projectionName}"?`, status: "warning" });
                            }}>
                                <IconButtonMui bgColor={"#d44240"}>
                                    <Delete />
                                </IconButtonMui>
                            </IconButton>
                        </Grid>
                    </Grid>
                ),
            }
        });
        setModelTable(models);
    }

    return (
        <CustomTemplate title="Registro de Proyecciones de Inversión">
            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        columns={columns}
                        data={modelTable}
                        adjustColumn={["actions"]}
                        filterable
                        customBtn={
                            (<Grid item xs={6} container justifyContent={"right"}>
                                <Grid item>
                                    <CustomButton
                                        variant="outlined"
                                        onClick={() => handleCreateProjection()}
                                    >
                                        Nueva proyección
                                    </CustomButton>
                                </Grid>
                            </Grid>)
                        }
                    />
                </Grid>
            </Grid>
            {open.open && open.component === COMPONENTS.DELETE &&
                <ConfirmationModal
                    open={open.open}
                    onClose={() => setOpen({ open: false, component: null, message: null, status: null })}
                    onSucces={() => handleDeleteProjection()}
                    isAvailableCancel={true}
                    isAvailableAcept={true}
                    message={open.message}
                    status={open.status}
                />}
            {open.open && open.component === COMPONENTS.SUCCESS &&
                <ConfirmationModal
                    open={open.open}
                    onSucces={() => { setOpen({ open: false, component: null, message: null, status: null }); projectionsQuery.refetch(); }}
                    isAvailableCancel={false}
                    isAvailableAcept={true}
                    message={open.message}
                    status={open.status}
                />}
            <ConfirmationModal
                open={showConfimationConfig}
                onSucces={() => setShowConfirmationConfig(false)}
                isAvailableCancel={false}
                isAvailableAcept={true}
                message={`No se puede crear la proyección debido a que falta la configuración del tipo de cambio.`}
                status={"warning"}
            />
        </CustomTemplate>
    )
}