import { Accordion, Grid, Icon } from "@mui/material";
import styled from "styled-components";

export const StyledIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    transform: rotate(90deg);

    rect{
      fill: white;
    }
  }
`;

export const StyledIconModalDetail = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    transform: rotate(-90deg);

    rect{
      fill: white;
    }

    path{
      fill: #fd932f;
    }
  }
`;

export const StyledBattery = styled(Grid)`
  .textOnlyBattery{
    div{
        background-color: #fff8f2;
    }
  }

  .detailBattery{
    background-color: #fff8f2;
    .accordionContent{
      align-text: center;
    }
  }
`;

export const StyledDeductions = styled(Grid)`
  .textOnlyDeductions{
    div{
        background-color: #dfffe8;
    }
  }
`;

export const StyledDetail = styled(Accordion)`
    box-shadow: none !important;

    .MuiAccordionSummary-root{
        padding: 0;
        border-bottom: 2px solid #a3b6f7;
    }

    .MuiAccordionDetails-root{
        padding: 20px 0px 0px;
    }

    .MuiSvgIcon-fontSizeMedium{
        font-size: 3rem;
        color: #ff922d;
    }
`;

export const StyledAccordionDetails = styled.div`
    background-color: #e9eefe;
    padding: 16px;
    margin-top: 16px;

    /* Estilo para el botón del tab */
    .MuiTab-root {
        font-weight: bold; 
        color: #000; 

        &.Mui-selected {
            color: #000;
        }
    }
    .MuiTabs-indicator {
        background-color: #ff922d;
    }
`;


export const StyledTableWrapper = styled.div`
  .MuiTableContainer-root {
    overflow: hidden;
    background-color: ${({ containerBgColor }) => containerBgColor || '#e9eefe'};
    border-radius: 3px; /* Bordes redondeados del contenedor */
  }

  .MuiTable-root {
    display: table;
    width: 100%;
    border-collapse: separate; /* Desactiva el colapso de bordes */
    border-spacing: 0; /* Sin espacio entre celdas */
  }

  .MuiTableCell-head {
    font-weight: bold;
    background-color: #496fef; /* Fondo de los encabezados */
    color: #fff;
    text-align: center;
    border-bottom: 5px solid ${({ containerBgColor }) => containerBgColor || '#e9eefe'};
  }

  .MuiTableCell-head:first-of-type {
    border-top-left-radius: 3px; /* Bordes redondeados del encabezado */
    border-bottom-left-radius: 3px;
  }

  .MuiTableCell-head:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .MuiTableRow-root {
    background-color: #e9eefe; /* Fondo para cada fila del cuerpo */
  }

  .MuiTableCell-body {
    color: #000;
    font-size: 14px;
    text-align: center;
    padding: 8px;
    background-color: #ffffff; /* Fondo blanco para todas las celdas */
    border-bottom: 2px solid #9c9c9c; /* Borde inferior */
  }

  /* Bordes redondeados para las primeras y últimas celdas del cuerpo */
  .MuiTableCell-body:first-of-type {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 3px;
  }

  .MuiTableCell-body:last-of-type {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 3px;
  }

  /* Condiciones para mostrar estilos en las filas */
  ${({ type }) => type === 1 &&
    `
      .MuiTableRow-root:nth-of-type(1) .MuiTableCell-body {
        background-color: #e0ffe8;
        overflow: hidden; /* Cubre toda la celda */
      }

      .MuiTableRow-root:nth-last-of-type(1) .MuiTableCell-body {
        background-color: #fff4eb;
        overflow: hidden;
      }
    `}
`;


export const StyledNameProjection = styled(Grid)`
  background-color: #e8eefe;
  padding: 1.5rem;
`;