import { Grid, Typography } from "@mui/material"
import { CustomCard, ContentCard } from "./styled.components"

export const Information = ({ data }) => {
    return (
        <>
            <Grid container  columnSpacing={{ xs: 1, sm: 3, md: 11 }} rowSpacing={2} >
                {data?.map((value, index) => (
                    <Grid item key={index}>
                        <CustomCard sx={{ backgroundColor: "#e9eefe" }}>
                            <Grid item xs={12}><Typography variant="body2">{value.title}</Typography></Grid>
                            <ContentCard item xs={12}>{value.body}</ContentCard>
                        </CustomCard>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}