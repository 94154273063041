import {
  Modal,
  Dialog,
  Button,
  Backdrop,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ModalRoot, ContentModal, FooterActions, DialogMui } from "../../views/TariffProcess/styled.components";
import { Loader } from "../Base/Loader";
import CustomButton from "../CustomButtonStyled/CustomButton";

export const CustomModal = ({
  title,
  open,
  isLoading,
  labelAccept,
  isDisabledCancel,
  close,
  onSuccess,
  children,
  width,
  fullWidth,
  maxWidth
}) => {

  return (
    <>
      <DialogMui
        fullWidth={fullWidth ?? false}
        width={width ?? "40%"}
        maxWidth={maxWidth ?? "lg"}
        open={open}
        onClose={close}
      >
        <DialogTitle alignSelf={"center"}>
          <h3>{title}</h3>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={4} marginBottom={3} justifyContent={"center"}>
            <Grid item>
              {!isDisabledCancel &&
                <Button variant="outlined" color="error" onClick={close}>
                  Cancelar
                </Button>
              }
            </Grid>
            <Grid item>
              <CustomButton
                variant="contained"
                onClick={onSuccess}
                disabled={isLoading}
              >
                {labelAccept}
              </CustomButton>
            </Grid>
          </Grid>
        </DialogActions>
      </DialogMui>
      <Backdrop style={{ zIndex: 10000, color: 'white', textAlign: "center" }} open={isLoading}>
        <Loader size={'30px'} />
      </Backdrop>
    </>
  );
};
