import { useMutation } from "react-query";
import {axiosServicev2} from "./../../../apiService/axiosServiceSecurity";
import { ShowMessage } from "../../../utils/utils";

const getLogin = (data) => {
    return axiosServicev2.post("login", data);
};

export const useLogin = () => {
    return useMutation({
        mutationFn: (data) =>
            getLogin(data)
                .then((res) => {
                    return res
                })
                .catch((err) => {
                    console.log("err", err);
                    const errorMessage = err.response?.data?.error ?? "Hubo un error en el servidor, favor de contactar a soporte";
                    ShowMessage(errorMessage, "error");
                    return null
                }),
        onError: (err, variables, context) => {
            const errorMessage = err.response?.data?.message ?? "Ocurrió un error, favor de contactar a sistemas";
            ShowMessage(errorMessage, "error");
        },
        onSuccess: (data, variables, context) => {
            if (data) {
                const successMessage = data.message ?? "Bienvenido al sistema";
                ShowMessage(successMessage, "success");
            }
        },
    });
};
