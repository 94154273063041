export const dataProjections = [
    {id: 100025, nameProjection: "PI_Chedrahui011215", megaWatts: "100Mw", downloadTime: "3hrs"},
    {id: 100026, nameProjection: "PI_Chedrahui011216", megaWatts: "101Mw", downloadTime: "4hrs"},
    {id: 100027, nameProjection: "PI_Chedrahui011217", megaWatts: "102Mw", downloadTime: "5hrs"},
    {id: 100028, nameProjection: "PI_Chedrahui011218", megaWatts: "103Mw", downloadTime: "6rs"},
    {id: 100029, nameProjection: "PI_Chedrahui011219", megaWatts: "104Mw", downloadTime: "7hrs"}
]

export const optionsTimeDownload = [
    { description: "1", value: 1 },
    { description: "2", value: 2 },
    { description: "3", value: 3 },
    { description: "4", value: 4 },
    { description: "5", value: 5 },
    { description: "6", value: 6 },
    { description: "7", value: 7 },
    { description: "8", value: 8 },
];

export const columnsProjections = [
    { id: "projectionId", label: "Id" },
    { id: "projectionName", label: "Nombre de la proyección de inversión" },
    { id: "megaWatts", label: "MegaWatts" },
    { id: "dischargeTime", label: "Tiempo de descarga (hrs)" },
    { id: "actions", label: "Acciones" },
];

export const columnsNodes = [
    { id: "id", label: "N°" },
    { id: "node", label: "Nodo" },
    { id: "load", label: "Carga" },
    { id: "unload", label: "Descarga" },
    { id: "difference", label: "Diferencia" },
    { id: "perDay", label: "Por día" },
    { id: "perMonth", label: "Por mes" },
    { id: "perYear", label: "Por año" },
    { id: "actions", label: "Acciones" },
];

export const createProjections = {
    nameProjection: "",
    megaWatts: "",
    timeDownloadId: 1,
    nodeId: 2,
    charge:0,
    download:0,
    difference: 0,
    loadingSchedule: 0,
    downloadSchedule: 0,
    perDay: 0,
    perMonth: 0,
    perYear: 0,
    batteryId: 2,
    deduction: 5,
    investmentCostUSD: 0,
    investmentCostMXN: 0,
    discountedNodeYear: 0,
    returnInvestmentYear: 0,
    returnInvestmentMonth: 0
}

export const MAXINTERGER = 14;
export const MAXDECIMAL = 2;