import { QueryClient } from 'react-query';

const queryConfig = {
    queries: {
        useErrorBoundary: true,
        refetchOnWindowFocus: false,
        retry: false,
    },

};

const queryClient = new QueryClient({ defaultOptions: queryConfig });

export default queryClient;
