import React from 'react';
import { Modal, IconButton, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ModalRoot, ContentModal } from "../styled.components";

const CustomIconButton = styled(IconButton)({
  position: 'absolute',
  right: '8px',
  top: '8px',
  color: '#496FEF',
});

const ViewModalPdf = ({open, handleClose, pdfUrl, pdfName }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="pdf-modal-title"
      aria-describedby="pdf-modal-description"
    >
      <ModalRoot style={{maxWidth: '70%', minHeight: '80%',margin: 'auto'}}>
        <CustomIconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CustomIconButton>
        <Typography variant="h6" component="h2" style={{ textAlign: 'center', color: '#2756f3', fontSize: '28px', fontWeight: 'bold', height: '40px'}}>
          Periodo {pdfName}
        </Typography>
        <ContentModal style={{padding: '0px 20px 0px'}}>
          <iframe
            src={pdfUrl}
            title="PDF Viewer"
            width="100%"
            height="630px"
            style={{ border: 'none' }}
          ></iframe>
        </ContentModal>
      </ModalRoot>
    </Modal>
  );
};

export default ViewModalPdf;