import { useMutation, useQuery } from "react-query";
import { ShowMessage } from "../../../utils/utils";
import axiosServiceV1 from "../../../apiService/axiosService";

const processTariffFetch = async (month, year, formData) => {
  return axiosServiceV1.post(
    `TariffProcessing/create-tariff-process/${year}/${month}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }
  );
};

export const getTariffProcessFetch = async () => {
  return axiosServiceV1.get("TariffProcessing/processed-tariffs");
};

export const useGetTariffProcess = (isUpdated) => {
  return useQuery({
    queryKey: ["getTariffProcess", isUpdated],
    queryFn: async () => await getTariffProcessFetch(),
    enabled: true,
  });
};

export const useTariffProcess = () => {
  const mutation = useMutation(({ month, year, formData }) =>
    processTariffFetch(month, year, formData)
  );

  const processTariff = async (month, year, formData) => {
    return await mutation.mutateAsync({ month, year, formData });
  };

  return {
    processTariff,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    isSucess: mutation.isSuccess,
  };
};
