import { Grid } from "@mui/material"
import { ContentTitle, ContentSection } from "./styled.components"

export const ViewSection = ({ title, custombtn, divider, children }) => {
    return (
        <ContentSection item container xs={12}>
            <ContentTitle item container xs={12}>
                <Grid item container xs={6} justifyContent={"flex-start"} alignItems={"center"}>
                    {title}
                </Grid>
                <Grid item container xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                    {custombtn}
                </Grid>
                {divider}
            </ContentTitle>
            <Grid item container xs={12}>
                {children}
            </Grid>
        </ContentSection>
    )
}