import {
    FormatBold,
    FormatItalic,
    FormatUnderlined,
    StrikethroughS,
    FormatAlignLeft,
    FormatAlignCenter,
    FormatAlignJustify,
    FormatAlignRight
} from "@mui/icons-material"
import { IconButton, Grid, FormControl, MenuItem, TextField, Divider } from "@mui/material"
import { useState } from "react";
import { FontSizePlugin } from "./FontSizePlugin";
import { StyledContainerFontSize } from "../styles";

const BLOCKTYPETOBLOCKNAME = [
    { value: 1, label: "Encabezado 1" },
    { value: 2, label: "Encabezado 2" },
    { value: 3, label: "Encabezado 3" },
    { value: 4, label: "Encabezado 4" },
    { value: 5, label: "Encabezado 5" },
    { value: 6, label: "Encabezado 6" },
];

const FONT_FAMILY_OPTIONS = [
    ["Arial", "Arial"],
    ["Courier New", "Courier New"],
    ["Georgia", "Georgia"],
    ["Times New Roman", "Times New Roman"],
    ["Trebuchet MS", "Trebuchet MS"],
    ["Verdana", "Verdana"]
];

export const ViewFormatText = ({ editor }) => {
    const [blockType, setBlockType] = useState("Parrafo");
    const [fontFamily, setFontFamily] = useState("Arial");
    const [fontSize, setFontSize] = useState("10px");

    const handleChange = (event, setter) => {
        setter(event.target.value);
    };

    return (
        <Grid container columnSpacing={2}>
            <Grid item>
                <TextField
                    labelId="demo-simple-select-label"
                    select
                    id="demo-simple-select"
                    value={blockType}
                    onChange={(event) => handleChange(event, setBlockType)}
                    variant="standard"
                    className="selectToolbar"
                >
                    <MenuItem value={"Parrafo"} onClick={() => editor.chain().focus().setParagraph().run()} >Parráfo</MenuItem>
                    {BLOCKTYPETOBLOCKNAME.map((value, index) => (
                        <MenuItem key={index} value={value.value} onClick={() => editor.chain().focus().toggleHeading({ level: value.value }).run()} >{value.label}</MenuItem>
                    ))}
                    <MenuItem value={"listOl"} onClick={() => editor.chain().focus().toggleOrderedList().run()} >Lista númerica</MenuItem>
                    <MenuItem value={"listUl"} onClick={() => editor.chain().focus().toggleBulletList().run()} >Lista de viñetas</MenuItem>
                </TextField>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <FormControl fullWidth>
                    <TextField
                        labelId="demo-simple-select-label"
                        select
                        id="demo-simple-select"
                        value={fontFamily}
                        onChange={(event) => handleChange(event, setFontFamily)}
                        variant="standard"
                        className="selectToolbar"
                    >
                        {FONT_FAMILY_OPTIONS.map((value, index) => (
                            <MenuItem key={index} value={value[0]} onClick={() => editor.chain().focus().setFontFamily(value[0]).run()}>{value[1]}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <StyledContainerFontSize item style={{paddingLeft: 0}}>
                <FontSizePlugin 
                    selectionFontSize={fontSize.slice(0, -2)}
                    editor={editor}
                    disabled={false}
                />
            </StyledContainerFontSize>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().toggleBold().run()}>
                    <FormatBold className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <FormatItalic className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item onClick={() => editor.chain().focus().toggleStrike().run()}>
                <IconButton>
                    <StrikethroughS className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item onClick={() => editor.chain().focus().setTextAlign("left").run()}>
                <IconButton>
                    <FormatAlignLeft className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().setTextAlign("center").run()}>
                    <FormatAlignCenter className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().setTextAlign("right").run()}>
                    <FormatAlignRight className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().setTextAlign("justify").run()}>
                    <FormatAlignJustify className="iconmui" />
                </IconButton>
            </Grid>
        </Grid>
    )
}