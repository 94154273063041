import { Suspense } from "react";
import { QueryClientProvider } from "react-query";
import queryClient from "./apiService/queryClient";
import { Toaster } from "react-hot-toast";
import Login from "./views/Login/Login";
import LayoutContainer from "./components/Layout/LayoutContainer";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
      primary: {
        main: '#496FEF',  // principal
      },
      secondary: {
        main: '#3959A5', // tabla
        light: "#FF0000"
      },
      text: {
        primary: '#FFFFFF', 
        secondary: '#000000', 
      },
      grey: {
          main: "#DADDE2"
      }
  
    },
  });  

  
export default function Root({authenticated}) {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    {authenticated === true && (
                        <>
                        <LayoutContainer team={true} />
                    </>
                    )}
                    {authenticated !== true && (
                        <Login />
                    )}
                    <Toaster position="bottom-right" />
                </QueryClientProvider>
            </ThemeProvider>
        </Suspense>
    );
}
