import { Grid } from "@mui/material";
import { StyledTable } from "../styled.components"
import { useEffect, useState } from "react";

export const TablePay = ({ column, title, data, color }) => {

    const formatNumeric = (value) => {
        if(column > 0)
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value,);
        return value
    }

    const handleTotal = () => {
        let billingPeriod = 0;
        billingPeriod = data.reduce((accumulator, currentValue) => accumulator + currentValue.billingPeriod, 0);
        return formatNumeric(Math.round(data[0].lightingFee + billingPeriod));
    }

    return (
        <StyledTable key={`${title}-${column}`} baseColor={color}>
            <Grid key={`head-${column}`} className="head">
                <Grid container columnSpacing={2}>
                <Grid item xs={12}>{title}</Grid>
                {(data?.length > 1) ?
                    <>
                        <Grid item xs={6} className="period">Periodo 1</Grid>
                        <Grid item xs={6} className="period">Periodo 2</Grid>
                    </>
                    : null}
                </Grid>
            </Grid>
            <Grid container columnSpacing={2}>
                {data?.map((value, index) => (
                    <Grid item key={`body-${title}-${column}-${index}`}>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.fixedCharge)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.energy)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.lowVoltage2p)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.powerFactor)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.subtotal)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.tax)}</Grid>
                        <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.billingPeriod)}</Grid>
                        {(data?.length <= 1 || column === 0) ? <Grid column={`body-${column}${index}`} className={`body ${(column === 0) ? "conceptcolumn" : "valuecolumn"}`}>{formatNumeric(value.lightingFee)}</Grid> : null}
                        {(data?.length <= 1 || column === 0) ? <Grid column={`body-${column}${index}`} className={`body footer`}>{formatNumeric(value.totalAmount)}</Grid> : null}
                    </Grid>
                ))}
            </Grid>
            {(data?.length > 1) ? <Grid key={`body-${title}126`} item className="footer">{formatNumeric(data[0].lightingFee)}</Grid> : null}
            {(data?.length > 1) ?<Grid key={`total-${column}`} item className="footer">{(column === 0) ? data[0].totalAmount : "" } {(data?.length > 1) ? handleTotal() : formatNumeric(data.totalAmount)}</Grid>: null}
        </StyledTable>
    )
}