import { Grid } from "@mui/material";
import styled from "styled-components";

export const ContentTitle = styled(Grid)`
  margin-bottom: 1rem !important;
`;

export const ContentSection = styled(Grid)`
  padding-bottom: 2rem !important;
`;

export const DividerTitle = styled.hr`
  width: 100% !important;
  border-color: ##a9b3c5 !important;
  border-width: 1px !important;
`;