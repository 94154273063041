import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled as styledMaterial } from "@mui/material/styles";

const CustomTableContainer = styledMaterial(TableContainer)({
  borderTopLeftRadius: '10px',
  borderTopRightRadius: '10px',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
  overflow: 'hidden', // Asegurar que el contenido no sobresalga de los bordes redondeados
});

const CustomTableCell = styledMaterial(TableCell)({
  fontWeight: "bold",
  backgroundColor: "#496fef",
  color: "#fff",
  borderBottom: "5px solid #fff",
  "&:first-of-type": {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  "&:last-of-type": {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  }
});

const CustomTableCellBody = styledMaterial(TableCell)({
  backgroundColor: "#e9eefe",
  color: "#000",
  borderBottom: "5px solid #fff",
  "&:first-of-type": {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  "&:last-of-type": {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  }
});

const StyledTableRow = styledMaterial(TableRow)({
  backgroundColor: "#e9eefe",
  "&:not(:last-child)": {
    marginBottom: "8px",
  },
});

const GenericTable = ({
  columns,
  data,
  adjustColumn = [],
  filterable = false,
}) => {
  const [filter, setFilter] = useState("");

  const filteredData = data.filter((row) => {
    return Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(filter.toLowerCase())
    );
  });

  const isWideCell = (columnId) => {
    return adjustColumn.includes(columnId);
  };

  return (
    <CustomTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <CustomTableCell key={column.id}>{column.label}</CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filterable ? (
            filteredData.map((row, index) => (
              <StyledTableRow key={index}>
                {columns.map((column) => (
                  <CustomTableCellBody
                    key={column.id}
                    sx={isWideCell(column.id) ? { width: "200px" } : {}}
                  >
                    {row[column.id]}
                  </CustomTableCellBody>
                ))}
              </StyledTableRow>
            ))
          ) : (
            data.map((row, index) => (
              <StyledTableRow key={index}>
                {columns.map((column) => (
                  <CustomTableCellBody
                    key={column.id}
                    sx={isWideCell(column.id) ? { width: "200px" } : {}}
                  >
                    {row[column.id]}
                  </CustomTableCellBody>
                ))}
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </CustomTableContainer>
  );
};

export default GenericTable;
