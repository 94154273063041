import { useState } from "react";
import { Button, Fade, Menu, MenuItem } from "@mui/material"
import { MoreHoriz } from "@mui/icons-material";

export const ViewTable = ({ editor, setMoreTable }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                className="iconmui-table"
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                disableElevation
                startIcon={<MoreHoriz className="iconmui"/>}
            >
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={() => {editor.chain().focus().addColumnBefore().run(); handleClose()}}>Insertar columna a la izquierda</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().addColumnAfter().run(); handleClose()}}>Insertar columna a la derecha</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().addRowBefore().run(); handleClose()}}>Insertar fila arriba</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().addRowAfter().run(); handleClose()}}>Insertar fila abajo</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().deleteColumn().run(); handleClose()}}>Eliminar columna</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().deleteRow().run(); handleClose()}}>Eliminar fila</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().deleteTable().run(); handleClose(); setMoreTable(false)}}>Eliminar tabla</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().mergeCells().run(); handleClose()}}>Combinar celdas</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().splitCell().run(); handleClose()}}>Dividir celda</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().toggleHeaderColumn().run(); handleClose()}}>Columna de encabazado</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().toggleHeaderRow().run(); handleClose()}}>Fila de encabazado</MenuItem>
                <MenuItem onClick={() => {editor.chain().focus().toggleHeaderCell().run(); handleClose()}}>Celda de encabazado</MenuItem>
            </Menu>
        </>
    )
}