import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  height: 95vh;
  overflow: auto;
  padding: 15px 15px 0;
`;

