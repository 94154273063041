import { Grid } from "@mui/material";
import styled from "styled-components";

export const EditorStyle = styled(Grid)`
    border: 1px solid #d3d6dd;
    border-radius: 3px;
    margin-top: 0 !important;

    /* Character count */
  .character-count {
    align-items: center;
    color: #808080;
    display: flex;
    font-size: 0.75rem;
    gap: .5rem;
    margin: 1.5rem;

    svg {
      color: #679c06;
    }

    &--warning,
    &--warning svg {
      color: #fd932f;
    }
  }

    .toolbar{
        border-bottom: 1px solid #d3d6dd;
        .iconmui-redo-undo{
            font-size: 18px !important;
            opacity:0.5 !important;
        }
        .iconmui{
            font-size: 18px !important;
            color: #666666;
        }
        .colorPicker{
            position: absolute;
            bottom: 17%;
        }
        .selectToolbar{
          .MuiInputBase-root{
            color:#666666;
          }
          .MuiInputBase-root::before{
            border-bottom: none;
          }
          .MuiInputBase-root::after{
            border-bottom: none;
          }
          .MuiInputBase-root:hover{
            border-bottom: none;
          }
        }
        .color-text-mui{
            min-width: auto;
        }
        .color-picker{
          padding:0;
        }
    }

    .editorContent{
      .ProseMirror{
        height: 25vh;
        min-height: 25vh;
        overflow-y: scroll;
        padding-top: 0.7rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      .ProseMirror-focused{
        outline: none;
      }
    }
`;

export const StyleFontSize = styled(Grid)`
  .inputFontSize{
    font-weight: 700;
    font-size: 14px;
    color: #777;
    border-radius: 5px;
    border-color: gray;
    height: 25px;
    margin-top: 5px;
    padding: 2px 4px;
    text-align: center;
    width: 35px;
  }
  input[type=number] { -moz-appearance:textfield; }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

export const StyledContainerFontSize = styled(Grid)`
  padding-left: 0;
`;