import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';

export const getAllViewCalculation = () => {
  return axiosServiceV1.get(`electricityBill/electricity-bill/view-calculations`);
};

export const useGetAllViewCalculation = () => {
  return useQuery({
      queryKey: ['viewCalculations'],
      queryFn: () => getAllViewCalculation().then(res => {
        return res;
      }).catch(err => [])
  });
};

export const getElectricityDetail = (id) => {
  return axiosServiceV1.get(`electricityBill/electricity-bill/detail/${id}`);
};

export const useGetElectricityDetail = (id) => {
  return useQuery({
      queryKey: ['electricitydetail', id],
      queryFn: () => getElectricityDetail(id),
      enabled: false
  });
};

export const getElectricityBill = (id) => {
  return axiosServiceV1.get(`electricityBill/electricity-bill/${id}`);
};

export const useGetElectricityBill = (id) => {
  return useQuery({
      queryKey: ['electricitybill', id],
      queryFn: () => getElectricityBill(id),
      enabled: false
  });
};
 


export const processElectricityBill = (formData) => {
  // Crear un objeto FormData
  const data = new FormData();
  for (const key in formData) {
    data.append(key, formData[key]);
  }

  // Realizar la llamada POST
  return axiosServiceV1.post(`ElectricityBill/process-electricity-bill`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Realizar proceso de carga de recibo
export const createProcessElectricityBill = (data) => {
  return axiosServiceV1.post(`ElectricityBill/process-electricity-bill`, data);
}

export const useCreateProcessElectricityBill = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar procesar el recibo', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              ShowMessage('Se proceso correctamente', 'success');
          }

      },

      mutationFn: (data) => createProcessElectricityBill(data).then(res => res).catch(err => null),
  });
};
 
export const getElectricityBillTariff= (id) => {
  return axiosServiceV1.get(`electricityBill/electricity-bill/tariff/${id}`);
};

export const useGetElectricityBillTariff = (id) => {
  return useQuery({
      queryKey: ['electricityBillTariff', id],
      queryFn: () => getElectricityBillTariff(id),
      enabled: false
  });
};
 

export const getElectricityBillCost = (receiptId, idCotizacion) => {
  return axiosServiceV1.get(`electricityBill/electricity-bill/energy-cost/${receiptId}/${idCotizacion}`);
};

export const useGetElectricityBillCost = (id, idCotizacion) => {
  return useQuery({
      queryKey: ['electricityBillCost', id],
      queryFn: () => getElectricityBillCost(id, idCotizacion),
      enabled: false
  });
};
 
export const saveProcessElectricityBill = (data) => {
  return axiosServiceV1.post(`ElectricityBill/electricity-bill/create-calculation`, data);
}

export const useSaveProcessElectricityBill = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar guardar la cotización', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              ShowMessage('Se guardo correctamente', 'success');
          }

      },

      mutationFn: (data) => saveProcessElectricityBill(data).then(res => res).catch(err => null),
  });
};

export const getElectricityBillFile = (receiptId) => {
  const url = `ElectricityBill/electricity-bill/${receiptId}/download`;
  const responseType = 'arraybuffer';
  return axiosServiceV1.get(url, { responseType });
};

export const useDownloadElectricityBillFile = (receiptId) => {
  return useQuery({
    queryKey: ['electricity-bill-file', receiptId],
    queryFn: () => getElectricityBillFile(receiptId)
      .then(res => {
        const mimeType = 'application/zip';
        const fileExtension = 'zip';
        downloadFile(res, `Recibo_${receiptId}`, mimeType, fileExtension);
      })
      .catch(err => {
        console.error("Error downloading file:", err);
        ShowMessage('Ocurrió un error al intentar descargar el archivo', 'error');
      }),
    enabled: false 
  });
};

const downloadFile = (data, name, mimeType, fileExtension) => {
  if (!data) {
    ShowMessage('Ocurrió un error al intentar descargar el archivo', 'error');
    return;
  }
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}.${fileExtension}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
