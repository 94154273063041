import { Grid, InputAdornment } from "@mui/material"
import { InputText, InputTextReadOnly } from "../../../../../components/Base/Inputs";
import { StyledDeductions } from "../../Projections.Styled";
import { useContext, useEffect, useState } from "react";
import { ProjectionsContext } from "../../../../../utils/context/projectionsContext";
import { COMPONENTS, currencyFormatterwhitinStyle, IntegerAndDecimalValidation, NumberFormat } from "../../../../../utils/utils";
import { ConfirmationModal } from "../../../../../components/Modal/ConfirmationModal";
import { MAXINTERGER, MAXDECIMAL } from "../../../interface/Data";

export const Deductions = () => {
    const { state, handleValues, selectNode, disabled } = useContext(ProjectionsContext);
    const [open, setOpen] = useState({ open: false, component: null, message: null, status: null });
    const [message, setMessage] = useState([]);

    const handleNumberValidation = (value, integer, decimal) => {
        const normalizedValue = value.toString().replace(",", ".");        
        if (IntegerAndDecimalValidation(normalizedValue, integer, decimal).isValid) {
            return normalizedValue;
        }
        return ""; 
    };

    useEffect(() => {
        if (!disabled && state?.timeDownloadId && state?.megaWatts && state?.costKw && state?.exchangeRate) {
            //const auxCostUSD = ((state?.timeDownloadId ?? 0) * ((state?.megaWatts ?? 0) * (state?.costKw * 1000))).toFixed(MAXDECIMAL)
            //const auxCostMXN = (auxCostUSD * (state?.exchangeRate?.exchangeRate ?? 0)).toFixed(MAXDECIMAL)
            const auxCostUSD = parseFloat((state.timeDownloadId ?? 0) * ((state.megaWatts ?? 0) * (state.costKw * 1000))).toFixed(MAXDECIMAL);
            const auxCostMXN = parseFloat((auxCostUSD * (state.exchangeRate?.exchangeRate ?? 0)).toFixed(MAXDECIMAL));

            const auxNode = state?.listNodes.find(x => x.nodeId === selectNode);
            const auxDeduction = parseFloat(state?.deduction / 100);
            const discountedNodeYear = ((auxNode?.perYear ?? 0) * (1 - auxDeduction ?? 0)).toFixed(2);
            setMessage([]);

            const auxCostUSDString = Number(auxCostUSD).toLocaleString('fullwide', { useGrouping: false });
            const auxCostMXNString = Number(auxCostMXN).toLocaleString('fullwide', { useGrouping: false });
            const finalAuxCostMXN = (auxCostMXN.toString().includes('e') || auxCostMXN.toString().includes('E')) ? auxCostMXNString : auxCostMXN;

            const returnInvestmentYear = discountedNodeYear !== 0 && discountedNodeYear !== null 
                ? (auxCostMXN / discountedNodeYear) === Infinity 
                    ? 0
                    : (auxCostMXN / discountedNodeYear).toFixed(2)
                : 0; 

            const returnInvestmentMonth = discountedNodeYear !== 0 && discountedNodeYear !== null 
                ? ((auxCostMXN / discountedNodeYear) * 12) === Infinity 
                    ? 0  
                    : ((auxCostMXN / discountedNodeYear) * 12).toFixed(2)
                : 0; 

            handleValues({
                ...state,
                investmentCostUSD: auxCostUSDString,
                investmentCostMSN: finalAuxCostMXN,
                discountedNodeYear: discountedNodeYear,
                returnInvestmentYear: returnInvestmentYear,
                returnInvestmentMonth: returnInvestmentMonth
            })
        }
    }, [selectNode, state?.deduction, state?.costKw]);


    const handleDeduction = (value) => {
        let numericValue = Number(value);
        if (numericValue < 0 || isNaN(numericValue)) {
            numericValue = 0; 
        } else if (numericValue > 100) {
            numericValue = 100; 
        }
    
        handleValues({ ...state, deduction: numericValue });
    };

    return (
        <StyledDeductions container marginTop={3} columnSpacing={3}>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputText
                        type="number"
                        title="Descuento por comprar a suministrador calificado"
                        value={state?.deduction == null || state?.deduction === 0 ? state?.discount : state?.deduction ?? ""}
                        onChange={(e) => handleDeduction(e.target.value)}
                        disabled={disabled}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        inputProps={{
                            min: 0
                        }}
                    //error={errors && errors.desiredUtility}
                    //helperText={errors.desiredUtility || ""}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        className={"textOnlyDeductions"}
                        title="Utilidad de nodo por año con descuento"
                        value={NumberFormat(handleNumberValidation(state?.discountedNodeYear ?? state?.annualUtilityWithDiscount ?? "", 34, MAXDECIMAL), "es-MX")}
                    />
                </Grid>
            </Grid>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        className={"textOnlyDeductions"}
                        title="Costo de inversión (USD)"
                        value={NumberFormat(handleNumberValidation(state?.investmentCostUSD ?? state?.investmentCostUsd ?? "", 34, MAXDECIMAL), "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        className={"textOnlyDeductions"}
                        title="Retorno de inversión por años"
                        value={currencyFormatterwhitinStyle({ currency: "MXN", value: state?.returnInvestmentYear ?? state?.returnOnInvestmentYears ?? "", minFractionDigits: 2 })}
                    />
                </Grid>
            </Grid>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        className={"textOnlyDeductions"}
                        title="Costo de Inversión (MXN)"
                        value={NumberFormat(handleNumberValidation(state?.investmentCostMSN ?? state?.investmentCostMxn ?? "", 34, MAXDECIMAL), "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        className={"textOnlyDeductions"}
                        title="Retorno de Inversión por meses"
                        value={currencyFormatterwhitinStyle({ currency: "MXN", value: state?.returnInvestmentMonth ?? state?.returnOnInvestmentMonths ?? "", minFractionDigits: 2 })}
                    />
                </Grid>
            </Grid>
            {open.open && open.component === COMPONENTS.WARNING &&
                <ConfirmationModal
                    open={open.open}
                    onSucces={() => setOpen({ open: false, component: null, message: null, status: null })}
                    isAvailableCancel={false}
                    isAvailableAcept={true}
                    message={open.message.map((value) => (
                        <p>{value}</p>
                    ))}
                    status={open.status}
                />}
        </StyledDeductions>
    )
}