import { Grid } from "@mui/material"
import CustomButton from "../../../components/CustomButtonStyled/CustomButton"

export const BreakdownPay = ({disabled, handleAdd}) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <CustomButton 
                    variant="outlined"
                    disabled={disabled}
                    onClick={handleAdd}
                >
                    Agregar cotizacion
                </CustomButton>
            </Grid>
            <Grid item xs={12}>

            </Grid>
        </Grid>
    )
}