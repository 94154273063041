import { useEffect, useState } from "react";
import { RUTA_API_BACK } from "../constans";
import StoreUser from "../storeUser";

function useMenu() {
  const [statusFetch, setStatusFetch] = useState("idle");
  const [routes, setRoutes] = useState();
  const [dataAuth, setDataAuth] = useState();
  const [loading, setLoading] = useState(false);

  const storeUser = new StoreUser();

  const dataAuth$ = storeUser.infoUser;

  useEffect(() => {
    const subscription = dataAuth$.subscribe((data) => setDataAuth(data));
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (dataAuth?.menu) {
      setRoutes(dataAuth.menu);
    }
  }, [dataAuth?.menu]);

  useEffect(() => {
    if (dataAuth?.userData) {
      fetchData();
    }
  }, [dataAuth?.userData]);

  const fetchData = () => {
    setLoading(true);
    const userId = dataAuth.userData?.userId;
    fetch(`${RUTA_API_BACK}permissions/menu/${userId}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${dataAuth.token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setStatusFetch("success");
          return response.json();
        }
        if (response.status === 401) {
          setStatusFetch("not_authorized");
          return null;
        }
        if (response.status === 500) {
          setStatusFetch("error");
          return null;
        }
        return [];
      })
      .then((data) => {
        if (data) {
          storeUser.addMenu(data);
          setRoutes(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        setStatusFetch("error");
        setLoading(false);
      });
  };

  return { statusFetch, routes, fetchData, loading };
}

export default useMenu;