import { Cancel, CheckCircle, Warning } from "@mui/icons-material";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import styled from "styled-components";
import { PrimaryButton, SecondaryButton } from "../Base/Buttons/Buttons";

// Estilos del modal
const StyledDialog = styled(Dialog)({
    "& .MuiPaper-root": {
        borderRadius: "10px",
        padding: "24px",
        width: "500px",
        height: "300px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
    },
});

const CustomButton = styled(Button)({
    backgroundColor: "#496FEF",
    color: "white",
    fontSize: "14px",
    padding: "6px 20px",
    borderRadius: "5px",
});

const getIconStatus = (status) => {
    switch (status) {
        case "success": return (<CheckCircle style={{ fontSize: 75, color: "#34c240" }} />)
        case "warning": return (<Warning style={{ fontSize: 75, color: "#fd932f" }} />)
        case "error": return (<Cancel style={{ fontSize: 75, color: "#d44241" }} />)
    }
}

// Component for the modal
export const ConfirmationModal = ({
    open,
    onClose,
    onSucces,
    message,
    status,
    isAvailableCancel,
    isAvailableAcept
}) => {
    return (
        <StyledDialog open={open} onClose={onClose}>
            <Grid container direction="column" alignItems="center" style={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Grid item style={{ marginBottom: 30 }}>
                    {getIconStatus(status ?? "success")}
                </Grid>
                <Grid item style={{ textAlign: 'center', color: 'black' }}>
                    { message }
                </Grid>
            </Grid>
            <Grid container justifyContent={"center"} columnSpacing={2}>
                {isAvailableCancel &&
                    <Grid item xs={6}>
                        <SecondaryButton
                            variant={"outlined"}
                            color={"error"}
                            onClick={onClose}
                            text={"Cancelar"}
                            style={{ width: "200px" }}
                        />
                    </Grid>
                }
                {isAvailableAcept &&
                    <Grid item xs={6}>
                        <PrimaryButton
                            variant="contained"
                            onClick={onSucces}
                            text={"Aceptar"}
                            style={{ width: "200px" }}
                        />
                    </Grid>
                }
            </Grid>
        </StyledDialog >
    );
};
