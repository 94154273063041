import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './root.component';
import StoreUser from './storeUser';

const Main = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const storeUser = new StoreUser();

  useEffect(() => {
    const checkAuthentication = () => {
      const isAuthenticated = storeUser.isAuthenticated();
      setAuthenticated(isAuthenticated);
    };

    checkAuthentication();

    const handleStorageChange = () => {
      checkAuthentication();
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [storeUser]);

  return (
    <React.StrictMode>
      <Root authenticated={authenticated} />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);