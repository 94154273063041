import { Grid, Typography } from "@mui/material"
import { CustomModal } from "../../../../../components/Modal/CustomModal"
import { TitleSection } from "../../../../../components/Base/TitleSection"
import { InputTextReadOnly } from "../../../../../components/Base/Inputs"
import { useEffect, useState } from "react"
import { Editor } from "../../../../../components/Editor"
import { StyledEditorDisabled } from "./Garanty.Styled"
import { NumberFormat } from "../../../../../utils/utils"

export const DetailGaranty = ({ data, open, close }) => {
    const [modelWarranty, setModelWarranty] = useState([]);

    useEffect(() => {
        if (data) {
            const auxData = JSON.parse(JSON.stringify([data]))
            const models = auxData.map((warranty) => {
                return {
                    id: warranty.id,
                    timeWarrantyName: warranty?.timeWarrantyName,
                    costKw: warranty.costKw,
                    desiredUtility: warranty.desiredUtility,
                    priceUser: warranty.priceUser,
                    characteristics: JSON.parse(warranty.characteristics)
                }
            });
            setModelWarranty(models);
        }
    }, [data]);

    useEffect(() => {
        return () => setModelWarranty([])
    }, [])

    return (
        <CustomModal
            title={"Detalle de garantía"}
            open={open}
            labelAccept={"Aceptar"}
            isDisabledCancel={true}
            width={"70%"}
            maxWidth={"80% !important"}
            close={() => close()}
            onSuccess={() => close()}
        >
            <Grid container columnSpacing={3}>
                <TitleSection title={"Configuración de garantía"} />
                <Grid item xs={3}>
                    <InputTextReadOnly
                        title="Tiempo de garantía"
                        value={modelWarranty[0]?.timeWarrantyName ?? ""}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputTextReadOnly
                        title="Costo Kw/h (USD)"
                        value={NumberFormat(modelWarranty[0]?.costKw ?? "", "es-MX")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputTextReadOnly
                        title="Utilidad deseada"
                        value={`${modelWarranty[0]?.desiredUtility ?? ""} %`}
                    />
                </Grid>
                <Grid item xs={3}>
                    <InputTextReadOnly
                        title="Precio al usuario"
                        value={NumberFormat(modelWarranty[0]?.priceUser ?? "", "es-MX")}
                    />
                </Grid>
                <StyledEditorDisabled item xs={12} mt={3}>
                    <Typography variant="subtitle2" gutterBottom>Características de la garantía</Typography>
                    <Editor
                        disabled={true}
                        importJSON={modelWarranty[0]?.characteristics}
                    />
                </StyledEditorDisabled>
            </Grid>
        </CustomModal>
    )
}