import { useParams } from "react-router-dom"
import { ContentSection } from "../../../components/Base/TitleSection/TitleSection.Style"
import CustomTemplate from "../../../components/CustomTemplate/CustomTemplate"
import { ProjectionsProvider } from "../../../utils/context/projectionsContext"
import { NewProjections } from "./NewProjections"

export const CreateProjectionsContainer = () => {
    const { id } = useParams();
    return (
        <ProjectionsProvider>
            <CustomTemplate title={!id ? "Registro de Proyecciones de Inversión" : "Detalle de Proyección de Inversión"} hasButtonBack>
                <ContentSection container>
                    <NewProjections />
                </ContentSection>
            </CustomTemplate>
        </ProjectionsProvider >
    )
}