import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useForm } from "../../../../hooks/useForm";
import { DefaultValidations, ShowMessage } from "../../../../utils/utils";
import CustomTemplate from "../../../../components/CustomTemplate/CustomTemplate";
import { InputText } from "../../../../components/Base/Inputs";
import { ConfigurationBattery } from "./ConfigurationGarantty";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../../components/Base/Buttons/Buttons";
import { ContentSection } from "../../../../components/Base/TitleSection/TitleSection.Style";
import { useNavigate, useParams } from "react-router-dom";
import { Editor } from "../../../../components/Editor";
import {
  useCreateBatteries,
  useGetBatteriesById,
  useUpdateBatteries,
} from "../../api/BatteryApi";
import { ConfirmationModal } from "../../../../components/Modal/ConfirmationModal";

export const FormNewBattery = () => {
  const history = useNavigate();
  const { id } = useParams();
  const batteryById = useGetBatteriesById(id);
  const [dataJSON, setDataJSON] = useState(null);
  const createBattery = useCreateBatteries();
  const updateBattery = useUpdateBatteries();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originalDataJSON, setOriginalDataJSON] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true); 

  const {
    setPropertiesValue,
    handleSubmit,
    handleChange,
    data: batteryData,
    errors,
  } = useForm({
    initialValues: null,
    validations: {
      brand: {
        default: DefaultValidations.ALPHANUMERIC,
        required: { message: "La marca es requerida" },
        custom: {
          isValidFn: (value) => {
            return value && value?.length <= 50;
          },
          message: "Solo se permiten un máximo de 50 carácteres",
        },
      },
      model: {
        default: DefaultValidations.ALPHANUMERIC,
        required: { message: "El modelo es requerido" },
        custom: {
          isValidFn: (value) => {
            return value && value?.length <= 50;
          },
          message: "Solo se permiten un máximo de 50 carácteres",
        },
      },
    },
    onSubmit: async () => {
      setLoading(true);

      const auxData = JSON.parse(JSON.stringify(batteryData));
      if (
        !batteryData?.listWarranty ||
        batteryData?.listWarranty?.length === 0
      ) {
        ShowMessage("Se requiere por lo menos agregar una garantía", "error");
        setLoading(false);
        return;
      } 

      const isCreating = !id;

      if (isFormDirty && !isCreating) {
        auxData.characteristicsBattery = JSON.stringify(dataJSON); 
      } else if (!isFormDirty && !isCreating) {
        auxData.characteristicsBattery = batteryById?.data?.characteristicsBattery; 
      } else if (isCreating) {
        auxData.characteristicsBattery = JSON.stringify(dataJSON);
      }

      try {
        let result;
        if (!id) {
          result = await createBattery.mutateAsync(auxData);
          if (result && result.batteryId) {
            setMessage(`La batería se dio de alta con éxito`);
            setShowConfirmationModal(true);
          }
        } else {
          result = await updateBattery.mutateAsync({
            data: auxData,
            id: id,
          });
          if (!result) {
            setMessage(`La información de la batería se editó con éxito`);
            setShowConfirmationModal(true);
          }
        }
      } catch (error) {
        ShowMessage("Ocurrió un error, intente nuevamente", "error");
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (batteryById?.data?.characteristicsBattery) {
      const parsedData = JSON.parse(batteryById.data.characteristicsBattery);
      setDataJSON(parsedData);
      setOriginalDataJSON(parsedData);
      setIsFirstLoad(false); 
    }
  }, [batteryById?.data?.characteristicsBattery]);

  useEffect(() => {
    if (batteryById.isSuccess && batteryById.data && !isFormDirty) {
      setPropertiesValue(batteryById.data);
    } else {
      setPropertiesValue(null);
    }
  }, [batteryById, isFormDirty]);

  useEffect(() => {
    if (!id) {
      batteryById.remove();
      setPropertiesValue(null);
      return;
    }
    batteryById.refetch();

    return () => {
      setPropertiesValue(null);
      batteryById.remove();
    };
  }, [id]);

  const handleInputChange = (field) => (e) => {
    setIsFormDirty(true);
    if (e.target.value.length <= 50) {
      setPropertiesValue({ [field]: e.target.value });
    }
  };

  const handleEditorChange = (newDataJSON) => {
    setDataJSON(newDataJSON);
    
    if (!isFirstLoad) {
      if (JSON.stringify(newDataJSON) !== JSON.stringify(originalDataJSON)) {
        setIsFormDirty(true);
      } else {
        setIsFormDirty(false);
      }
    }
  };

  return (
    <CustomTemplate
      title={!id ? "Nueva batería" : "Editar batería"}
      hasButtonBack
    >
      <ContentSection container>
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <InputText
              fullWidth
              title="Marca"
              value={batteryData?.brand}
              onChange={handleInputChange("brand")}
              error={errors && errors.brand}
              helperText={errors.brand || ""}
            />
          </Grid>
          <Grid item xs={3}>
            <InputText
              fullWidth
              title="Modelo"
              value={batteryData?.model}
              onChange={handleInputChange("model")}
              error={errors && errors.model}
              helperText={errors.model || ""}
            />
          </Grid>
          <Grid item xs={12} mt={3}>
            <Typography variant="subtitle2" gutterBottom>
              Descripción de la batería
            </Typography>
            {(batteryById.isSuccess && batteryById.data) || !id ? (
              <Editor
                limit={5000}
                disabled={false}
                setJSON={handleEditorChange}
                importJSON={
                  batteryById?.data?.characteristicsBattery
                    ? JSON.parse(batteryById?.data?.characteristicsBattery)
                    : ""
                }
              />
            ) : (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
        <ConfigurationBattery
          listWarranty={batteryData?.listWarranty}
          setListWarranty={(listWarranty) => {
            setPropertiesValue({ listWarranty: listWarranty });
            setIsFormDirty(true);
          }}
        />
        <Grid container mt={5} justifyContent={"center"} columnSpacing={2}>
          <Grid item xs={4} textAlign={"right"}>
            <SecondaryButton
              handleClick={() => {
                history(-1);
                batteryById.remove();
              }}
              text={"Cancelar"}
            />
          </Grid>
          <Grid item xs={4}>
            <PrimaryButton
              handleClick={async () => await handleSubmit()}
              text={"Guardar"}
              disabled={loading}
            />
          </Grid>
        </Grid>
      </ContentSection>
      <ConfirmationModal
        open={showConfirmationModal}
        onSucces={() => {
          setShowConfirmationModal(false);
          batteryById.remove();
          history(-1);
        }}
        isAvailableAcept={true}
        message={message}
      />
    </CustomTemplate>
  );
};
