import { Grid } from "@mui/material"
import { InputTextReadOnly } from "../../../../../components/Base/Inputs";
import { ProjectionsContext } from "../../../../../utils/context/projectionsContext";
import { useContext, useEffect, useState } from "react";
import { NumberFormat } from "../../../../../utils/utils";

export const DetailNode = () => {
    const { state, selectNode, disabled, handleValues } = useContext(ProjectionsContext);
    const [node, setNode] = useState(null);

    const getShedule = (list, interval) => {
        if(list){
            return list.find(x => x.interval === interval)?.value;
        }
        return null;
    }

    useEffect(() => {
        if (selectNode && Array.isArray(state?.listNodes)) {
            setNode(state?.listNodes.find(x => x.nodeId === selectNode));
            const auxNode = state?.listNodes.find(x => x.nodeId === selectNode);
            const nodeDate = new Date()
            handleValues({
                ...state,
                nodeId: selectNode,
                nodeDate: auxNode?.nodeDate ?? (nodeDate.getFullYear() + "-" + nodeDate.getMonth() + "-" + nodeDate.getDate()),
                charge: auxNode?.load,
                download: auxNode?.unload,
                difference: auxNode?.difference,
                loadingSchedule: getShedule(auxNode?.hoursList, auxNode?.loadInterval),
                downloadSchedule: getShedule(auxNode?.hoursList, auxNode?.unloadInterval),
                perDay: auxNode?.perDay,
                perMonth: auxNode?.perMonth,
                perYear: auxNode?.perYear,
            });
        } else if (!disabled) {
            handleValues({
                ...state,
                nodeId: null,
                nodeDate: null,
                charge: null,
                download: null,
                difference: null,
                loadingSchedule: null,
                downloadSchedule: null,
                perDay: null,
                perMonth: null,
                perYear: null,
            });
        }
    }, [selectNode]);

    useEffect(() => {
        if (!disabled && !state?.listNodes && !selectNode) return;

        if (selectNode && Array.isArray(state?.listNodes) && disabled) {
            const auxNode = state?.listNodes.find(x => x.nodeId === selectNode);
            setNode(auxNode);
        }
    }, [state?.listNodes, selectNode])

    return (
        <Grid container columnSpacing={3}>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Carga"
                        value={NumberFormat(node?.load ?? "", "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Descarga"
                        value={NumberFormat(node?.unload ?? "", "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Diferencia"
                        value={NumberFormat(node?.difference ?? "", "es-MX")}
                    />
                </Grid>
            </Grid>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Horario de Carga"
                        value={node?.loadInterval ?? ""}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Horario de descarga"
                        value={node?.unloadInterval ?? ""}
                    />
                </Grid>
            </Grid>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Por día"
                        value={NumberFormat(node?.perDay ?? "", "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Por mes"
                        value={NumberFormat(node?.perMonth ?? "", "es-MX")}
                    />
                </Grid>
                <Grid item xs={4} mt={2}>
                    <InputTextReadOnly
                        title="Por año"
                        value={NumberFormat(node?.perYear ?? "", "es-MX")}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}