import { Dialog, Grid, Icon, TextField } from "@mui/material";
import styled from "styled-components";
import Select from "../../components/Select/Select";

export const StyledIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    border-radius: 5px;

    rect{
      fill: #ff9841;
    }
  }
`;


export const StyledTextField = styled(TextField)`
  background-color: white;
  border-radius: 4px; /* Ajusta el borde redondeado aquí */
  overflow: hidden; /* Asegúrate de que el contenido se ajuste al borde redondeado */
  padding-top: 1px;
  

  & .MuiInputBase-root {
    border-radius: 4px; /* Asegúrate de que el input base también tenga el borde redondeado */
    
  }

  & .MuiInputBase-input {
    color: black;
    padding: 14px 14px;
  }

  & .MuiInputLabel-root {
    color: black;
  }

  &.Mui-disabled {
    color: gray;
    background-color: #f0f0f0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d6dd; /* Ajusta el color del borde */
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde al pasar el mouse */
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde cuando está enfocado */
  }
`;

export const StyledNameContent = styled(Grid)`
  background: #e9eefe !important;
  padding: 1rem;
  border-radius: 5px;
  .MuiFormHelperText-root{
    background: #e9eefe !important;
  }
`;


export const StyledDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '100%', 
    maxWidth: '600px', 
    minHeight: '200px',
    borderRadius: '16px',
    padding: '10px',  
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)', 
  },
});


export const StyledConfirm = styled(Grid)`
  .MuiSvgIcon-root {
    font-size: 45px;
    color: #ff922d;
  }
`;

export const IconContainer = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #ff9841; /* Cambia este color según lo que necesites */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledSelect = styled(Select)`
  width: 100% !important; // Asegúrate de aplicar el ancho deseado
  min-width: 200px !important; // Asegúrate de aplicar el min-width deseado
`;