import { Grid } from "@mui/material";
import styled from "styled-components";

export const UploaderStyled = styled(Grid)`
    border: 1px dashed #496fef;
    .uppy-Dashboard-inner{
        background-color: #e9eefe !important;
    }
    .filepond--wrapper{
        width: inherit;
        height: 250px;
        background-color: #e9eefe !important;
        .filepond--drop-label{
            height: 200px !important;
            background-color: #e9eefe !important;
        }
    }
    .filepond--drip{
        background: #e9eefe !important;
        opacity: 1;
    }
    .filepond--item-panel{
        background-color: #496fef !important;
    }
    
    .filepond--drop-label label{
        width: -webkit-fill-available;
    }
`;

export const StyledGenLabel = styled(Grid)`
    display: flex;
    margin-top: 15px !important;
    text-decoration: none;
    justify-content: center;
    

    .MuiSvgIcon-root{
        color: #496fef !important;
        font-size: 25px !important;
    }

    .footerlabel{
        padding: 10px;
        background-color: white;
        border-radius: 5px !important;
        border: 1px solid #CCCCCC !important;

        .iconfooter{
            margin-left: 50px;
        }
    }
`;

export const StyledIcon = styled(Grid)`
    color: #496fef !important;
    background-color: #e9eefe !important;
    font-size: 25px !important;
    cursor: pointer;
    text-align: left;
`;

export const PreviewTitle = styled(Grid)`
    .Helppreview{
        color: #496fef !important;
        font-size: 25px !important;
    }

    .Deletepreview{
        color: red !important;
        font-size: 25px !important;
    }
`;