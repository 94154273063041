import { Grid, Typography } from "@mui/material"
import { ContentTitle, ContentSection, DividerTitle } from "./TitleSection.Style"

export const TitleSection = ({ title, custombtn, divider = true }) => {
    return (
        <Grid item container xs={12}>
            <ContentTitle item container xs={12}>
                <Grid item container xs={6} justifyContent={"flex-start"} alignItems={"center"}>
                    {<Typography variant="subtitle1">{title}</Typography>}
                </Grid>
                <Grid item container xs={6} justifyContent={"flex-end"} alignItems={"center"}>
                    {custombtn}
                </Grid>
                {divider && <DividerTitle/> }
            </ContentTitle>
        </Grid>
    )
}