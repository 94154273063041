import { Card, Grid, Icon, TextField } from "@mui/material";
import styled from "styled-components";

export const ContentTable = styled.table`
  border: none !important;
  td{
    border: none !important;
  }
  thead{
    font-size: smaller;
  }
  tbody{
    font-size: small;
  }
`;

export const CustomCard = styled(Card)`
  width: 20rem;
  height: 7rem;
  padding: 5px;
`;

export const ContentCard = styled(Grid)`
  margin-left: 1rem;
  height: 70%;
  align-content: center;
`;

export const ContentTitle = styled(Grid)`
  margin-bottom: 1rem !important;
`;

export const ContentSection = styled(Grid)`
  margin-bottom: 2rem !important;
`;

export const DividerTitle = styled.hr`
  width: 100% !important;
  border-color: #496fef !important;
  border-width: 2px !important;
  margin-top: 10px;
`;

export const Divider = styled.hr`
  width: 100% !important;
  border-color: #a3b6f7 !important;
  border-width: 1.5px !important;
`;

export const ContainerTables = styled.section`
  width: 100%;
  height: calc(100vh - 315px);
  background-color: #E9EEFE;
  border-radius: 4px;
  padding: 8px 4px;

  >div{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const StyledTextField = styled(TextField)`
  background-color: white;
  border-radius: 4px; /* Ajusta el borde redondeado aquí */
  overflow: hidden; /* Asegúrate de que el contenido se ajuste al borde redondeado */

  & .MuiInputBase-root {
    border-radius: 4px; /* Asegúrate de que el input base también tenga el borde redondeado */
  }

  & .MuiInputBase-input {
    color: black;
  }

  & .MuiInputLabel-root {
    color: black;
  }

  &.Mui-disabled {
    color: gray;
    background-color: #f0f0f0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d6dd; /* Ajusta el color del borde */
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde al pasar el mouse */
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde cuando está enfocado */
  }
`;

export const StyledTextFieldReadOnly = styled(TextField)`
  background-color: white;
  border-radius: 4px; /* Ajusta el borde redondeado aquí */
  overflow: hidden; /* Asegúrate de que el contenido se ajuste al borde redondeado */

  & .MuiInputBase-root {
    border-radius: 4px; /* Asegúrate de que el input base también tenga el borde redondeado */
  }

  & .MuiInputBase-input {
    background-color: #E5E5E5;
    color: black;
  }

  & .MuiInputLabel-root {
    color: black;
  }

  &.Mui-disabled {
    color: gray;
    background-color: #f0f0f0;
  }

  &.Mui-focused .MuiInputLabel-root {
    border-color:  #E5E5E5; /* Ajusta el color del borde */
    border: none;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color:  #E5E5E5; /* Ajusta el color del borde */
    border: none;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color:  #E5E5E5; /* Color del borde al pasar el mouse */
    border: none;
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
    border-color:  #E5E5E5; /* Color del borde cuando está enfocado */
  }
`;

export const StyledNameContent = styled(Grid)`
  background: #e9eefe !important;
  padding: 1rem;
  border-radius: 5px;
  .MuiFormHelperText-root{
    background: #e9eefe !important;
  }
`;

export const StyledIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    border-radius: 5px;

    rect{
      fill: #ff9841;
    }
  }
`;

export const StyledImagenPreview = styled.img`
  height: 550px;
  width: -webkit-fill-available;
`;