import { Add, Remove } from "@mui/icons-material"
import { Grid, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { StyleFontSize } from "../styles";

const MIN_ALLOWED_FONT_SIZE = 8
const MAX_ALLOWED_FONT_SIZE = 72
const DEFAULT_FONT_SIZE = 15

var updateFontSizeType

    ; (function (updateFontSizeType) {
        updateFontSizeType[(updateFontSizeType["increment"] = 1)] = "increment"
        updateFontSizeType[(updateFontSizeType["decrement"] = 2)] = "decrement"
    })(updateFontSizeType || (updateFontSizeType = {}))

export const FontSizePlugin = ({ selectionFontSize, disabled, editor }) => {
    const [inputValue, setInputValue] = useState(selectionFontSize);
    const [inputChangeFlag, setInputChangeFlag] = useState(false)

    const calculateNextFontSize = (currentFontSize, updateType) => {
        if (!updateType) {
            return currentFontSize
        }

        let updatedFontSize = currentFontSize
        switch (updateType) {
            case updateFontSizeType.decrement:
                switch (true) {
                    case currentFontSize > MAX_ALLOWED_FONT_SIZE:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE
                        break
                    case currentFontSize >= 48:
                        updatedFontSize -= 12
                        break
                    case currentFontSize >= 24:
                        updatedFontSize -= 4
                        break
                    case currentFontSize >= 14:
                        updatedFontSize -= 2
                        break
                    case currentFontSize >= 9:
                        updatedFontSize -= 1
                        break
                    default:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE
                        break
                }
                break

            case updateFontSizeType.increment:
                switch (true) {
                    case currentFontSize < MIN_ALLOWED_FONT_SIZE:
                        updatedFontSize = MIN_ALLOWED_FONT_SIZE
                        break
                    case currentFontSize < 12:
                        updatedFontSize += 1
                        break
                    case currentFontSize < 20:
                        updatedFontSize += 2
                        break
                    case currentFontSize < 36:
                        updatedFontSize += 4
                        break
                    case currentFontSize <= 60:
                        updatedFontSize += 12
                        break
                    default:
                        updatedFontSize = MAX_ALLOWED_FONT_SIZE
                        break
                }
                break

            default:
                break
        }
        return updatedFontSize
    }

    const updateFontSizeInSelection = (newFontSize, updateType) => {
        editor.chain().focus().setFontSize(newFontSize || updateType).run()
    }

    const handleKeyPress = e => {
        const inputValueNumber = Number(inputValue)

        if (["e", "E", "+", "-"].includes(e.key) || isNaN(inputValueNumber)) {
            e.preventDefault()
            setInputValue("")
            return
        }
        setInputChangeFlag(true)
        if (e.key === "Enter" || e.key === "Tab" || e.key === "Escape") {
            e.preventDefault()

            updateFontSizeByInputValue(inputValueNumber)
        }
    }

    const handleInputBlur = () => {
        if (inputValue !== "" && inputChangeFlag) {
            const inputValueNumber = Number(inputValue)
            updateFontSizeByInputValue(inputValueNumber)
        }
    }

    const updateFontSizeByInputValue = inputValueNumber => {
        let updatedFontSize = inputValueNumber
        if (inputValueNumber > MAX_ALLOWED_FONT_SIZE) {
            updatedFontSize = MAX_ALLOWED_FONT_SIZE
        } else if (inputValueNumber < MIN_ALLOWED_FONT_SIZE) {
            updatedFontSize = MIN_ALLOWED_FONT_SIZE
        }

        setInputValue(String(updatedFontSize))
        updateFontSizeInSelection(String(updatedFontSize) + "px", null)
        setInputChangeFlag(false)
    }

    const handleButtonClick = updateType => {
        if (inputValue !== "") {
            const nextFontSize = calculateNextFontSize(Number(inputValue), updateType)
            setInputValue(nextFontSize)
            updateFontSizeInSelection(String(nextFontSize) + "pt", null)
        } else {
            updateFontSizeInSelection(null, updateType)
        }
    }

    useEffect(() => {
        setInputValue(selectionFontSize)
    }, [selectionFontSize])


    return (
        <StyleFontSize container>
            <Grid item xs={4}>
                <IconButton
                    onClick={() => handleButtonClick(updateFontSizeType.decrement)}
                    disabled={
                        (selectionFontSize !== "" &&
                            Number(inputValue) <= MIN_ALLOWED_FONT_SIZE)
                    }
                >
                    <Remove className="iconmui" />
                </IconButton>
            </Grid>
            <Grid item xs={4}>
                <input
                    type="number"
                    className="inputFontSize"
                    value={inputValue}
                    disabled={disabled}
                    min={MIN_ALLOWED_FONT_SIZE}
                    max={MAX_ALLOWED_FONT_SIZE}
                    onChange={e => setInputValue(e.target.value)}
                    onKeyDown={handleKeyPress}
                    onBlur={handleInputBlur}
                />
            </Grid>
            <Grid item xs={4}>
                <IconButton
                    onClick={() => handleButtonClick(updateFontSizeType.increment)}
                    disabled={
                        (selectionFontSize !== "" &&
                            Number(inputValue) >= MAX_ALLOWED_FONT_SIZE)
                    }
                >
                    <Add className="iconmui" />
                </IconButton>
            </Grid>
        </StyleFontSize>
    )
}