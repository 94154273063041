import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

const CustomButton = styled(Button)({
  border: `1px solid  #3959A5`,
  backgroundColor: "white",
  color: "#3959A5",
  fontSize: "14px",
  padding: "6px 20px",
  borderRadius: "5px",
  "&:hover": {
    backgroundColor: "#3959A5",
    color: "white",
  },
});

export default CustomButton;
