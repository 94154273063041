import React, { useContext, useEffect, useState } from 'react'

import { Grid, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import { PrimaryButtonWithIcon } from "../../../../components/Base/Buttons/Buttons";
import { CustomModal } from "../../../../components/Modal/CustomModal";
import { InputTextReadOnly } from "../../../../components/Base/Inputs";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import ChartPriceNodes from '../ChartPriceNodes/ChartPriceNodes';
import GenericTable from '../../../../components/DataTable/GenericTable';
import { IconArrow } from '../../../../components/Icon';
import { StyledAccordionDetails, StyledDetail, StyledIconModalDetail, StyledTableWrapper } from '../Projections.Styled';
import { ProjectionsContext } from '../../../../utils/context/projectionsContext';
import { useGetLocalMarginalPriceMDA } from '../../api/Projectionsapi';
import { ContainerPriceNode } from '../TablesPriceNodes/styled-component';
import { currencyFormatter } from '../../../../utils/utils';

const allColumns = [
    { id: "interval", label: "Horario de carga" },
    { id: "value", label: "$/Mwh" },
    { id: "interval", label: "Horario de descarga" },
    { id: "value", label: "$/Mwh" }
];

const ModalTarifNodeDetail = ({ isOpen, onClose }) => {
    const [view, setView] = useState("table")
    const [labels, setLabels] = useState([])
    const [dataChart, setDataChart] = useState([])
    const { selectedNodeDetail } = useContext(ProjectionsContext)

    const { data: nodesProjections } = useGetLocalMarginalPriceMDA(selectedNodeDetail.nodeId, selectedNodeDetail.nodeDate)
    const [dataArrays, setDataArrays] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        return () => {
            setView("table")
        }
    }, [])

    useEffect(() => {
        //obtener la cantidad de datos de la lista
        const count = selectedNodeDetail.hoursList.length;

        if (count > 0) {
            const sortedData = selectedNodeDetail.hoursList
                .slice()
                .map(item => ({
                    interval: item.interval,
                    value: item.value,
                }))
                .sort((a, b) => a.value - b.value);

            const firstColumn = sortedData.map(item => ({
                interval: item.interval,
                value: currencyFormatter({ style: "currency", currency: "MXN", value: item.value, minFractionDigits: 2 })
            }));

            const secondColumn = [...sortedData].reverse().map(item => ({
                interval: item.interval,
                value: currencyFormatter({ style: "currency", currency: "MXN", value: item.value, minFractionDigits: 2 })
            }));

            // asignar los datos a las columnas
            setDataArrays([firstColumn, secondColumn]);
            setColumns(allColumns);

            // obtener las etiquetas de las horas
            const labels = selectedNodeDetail.hoursList.map((value) => value.interval)
            // asignar las etiquetas
            setLabels(labels)

            // obtener los datos de los precios
            const datasetData = selectedNodeDetail.hoursList.map((value) => value.value)
            // asignar los datos
            setDataChart(datasetData)
        }
    }, [selectedNodeDetail])

    return (
        <CustomModal
            title={"Detalle de nodo y tarifas"}
            open={isOpen}
            isLoading={false}
            labelAccept={"Aceptar"}
            isDisabledCancel={true}
            close={onClose}
            onSuccess={onClose}
            width={"100%"}
            fullWidth={false}
            maxWidth={"lg"}

        >
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <InputTextReadOnly title={"Nodo"} value={selectedNodeDetail.node} />
                </Grid>
                <Grid container item xs={12} gap={2}>

                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <StyledDetail defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<StyledIconModalDetail><IconArrow /></StyledIconModalDetail>}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                >
                                    <Typography sx={{ fontWeight: 'bold' }}>Información General</Typography>
                                </AccordionSummary>
                                <AccordionDetails>

                                    <Grid item xs={4}>
                                        <PrimaryButtonWithIcon
                                            text={view === "table" ? "Ver gráfica" : "Ver tabla"}
                                            bgColor={"#34c85a"}
                                            handleClick={() => setView(view === "table" ? "chart" : "table")}
                                            icon={view === "table" ? <InsertChartOutlinedIcon /> : <TableChartOutlinedIcon />} />
                                    </Grid>

                                    <StyledAccordionDetails>
                                        {view === "table" ? (
                                            <StyledTableWrapper type={1}>
                                                <ContainerPriceNode $columncount={2}>
                                                    {dataArrays.map((data, index) => (
                                                        <GenericTable
                                                            key={index}
                                                            columns={columns.slice(index * 2, index * 2 + 2)}
                                                            data={data}
                                                            adjustColumn={["node", "price"]}
                                                        />
                                                    ))}
                                                </ContainerPriceNode>
                                            </StyledTableWrapper>
                                        ) : (
                                            <ChartPriceNodes
                                                labels={labels}
                                                datasets={[
                                                    {
                                                        label: 'Precios',
                                                        data: dataChart,
                                                        borderColor: "rgb(255, 159, 64)",
                                                        backgroundColor: "rgba(255, 159, 64)",
                                                        tension: 0.1,
                                                        pointRadius: 10,
                                                        pointHoverRadius: 15
                                                    },
                                                ]}
                                                xTitle="Horas"
                                                yTitle="$/MWh"
                                            />
                                        )}
                                    </StyledAccordionDetails>
                                </AccordionDetails>
                            </StyledDetail>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={12}>

                        <StyledDetail defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<StyledIconModalDetail><IconArrow /></StyledIconModalDetail>}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography sx={{ fontWeight: 'bold' }}>Precios Marginales Locales del MDA</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <StyledTableWrapper containerBgColor={'#fff'}>
                                    <GenericTable columns={[
                                        { id: "hour", label: "Hora" },
                                        { id: "localMarginalPrice", label: "Precio marginal local ($/MWh)" },
                                        { id: "energyComponent", label: "Componente de energía ($/Mwh)" },
                                        { id: "lossesComponent", label: "Componente de perdidas ($/Mwh)" },
                                        { id: "congestionComponent", label: "Componente de congestión ($/Mwh)" },
                                    ]}
                                        data={nodesProjections?.map(n => {
                                            n.hour = n.hour.toString().padStart(2, '0')
                                            return n;
                                        }) || []} />
                                </StyledTableWrapper>
                            </AccordionDetails>
                        </StyledDetail>

                    </Grid>
                </Grid>
            </Grid>
        </CustomModal>
    )
}

export default ModalTarifNodeDetail