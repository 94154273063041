import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Logo from '../../assets/logo2_.svg'; 

const boxStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  width: '100vw'
};

const logoStyle = {
  width: '600px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto'
};

function DashboardContainer() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simula la carga de datos
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 segundos de carga

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box sx={boxStyle}>
      <Box>
        <img src={Logo} alt="Logo" style={logoStyle} />
      </Box>
    </Box>
  );
}

export default DashboardContainer;