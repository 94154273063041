import React, { useEffect, useState } from 'react'

import { ContainerPriceNode } from './styled-components'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = (xTitle, yTitle) => ({
  responsive: true,
  plugins: {
    legend: {
      display: true,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: yTitle,
        color: '#496fef',
        font: {
          size: 24,
        },
      },
      ticks: {
        callback: function (value) {
          return '$' + value.toLocaleString();
        },
      },
    },
    x: {
      title: {
        display: true,
        text: xTitle,
        color: '#496fef',
        font: {
          size: 24,
        },
      },
      autoSkip: true,
    maxTicksLimit: 1
    },
  },
});

const ChartPriceNodes = ({ labels, datasets, xTitle, yTitle }) => {
  const [dataChart, setDataChart] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (labels && datasets) {
      const formattedDatasets = datasets.map((dataset, index) => ({
        label: dataset.label || `Dataset ${index + 1}`,
        data: dataset.data,
        borderColor: dataset.borderColor || 'rgba(75,192,192,1)',
        backgroundColor: dataset.backgroundColor || 'rgba(75,192,192,0.2)',
        pointStyle: dataset.pointStyle || 'circle',
        pointRadius: dataset.pointRadius || 5,
        pointHoverRadius: dataset.pointHoverRadius || 7,
      }));

      setDataChart({
        labels: labels,
        datasets: formattedDatasets,
      });
    }
  }, [labels, datasets]);

  return (
    <ContainerPriceNode>
      <Line data={dataChart} options={options(xTitle, yTitle)} />
    </ContainerPriceNode>
  );
};

export default ChartPriceNodes;

