import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Root = styled.main`
  height: inherit;
  padding: 32px 24px;
`;

export const ContentPage = styled.section`
  background-color: white;
  display: grid;

  height: 100%;
  align-content: start;
  border-radius: 16px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 32px 16px;
  position: relative;
  color: #496FEF;
  > hr{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    border: none;
    outline: none;
    height: 2px;
    background-color: #496FEF;
  }
`;

export const BodyContent = styled.div`
  padding: 24px;
  display: grid;
  gap: 32px;
`;

export const ContentActions = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  padding: 10px 0px;

  >div{
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
  }
`;

export const ContentListTables = styled.section`
  width: 100%;
  height: calc(100vh - 315px);
  background-color: #E9EEFE;
  border-radius: 4px;
  padding: 8px 4px;
  overflow-y: auto;

  >div{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const StyledDelete = styled(Grid)`
    padding: 10px;

    .MuiSvgIcon-root{
        font-size: 45px;
        color: #ff922d;
    }
`;