export const getPowerFactorCalculationRule = (totalKw) => {
    return totalKw > 1000 ? 95 : 90;
};

export const calculatePowerFactorPenalty = (powerFactor, totalKw) => {
    const round = Number((0.6 * ((totalKw / powerFactor) - 1)).toFixed(3));

    const powerFactorPenalty = round < 1.2 ? (totalKw > powerFactor ? round : 0) : isFinite(round) ? 1.2 : 0;

    return powerFactorPenalty;
};

export const calculatePowerFactorBonus = (totalKw, powerFactor) => {
    return powerFactor > totalKw ? Number((0.25 * (1 - (90 / powerFactor))).toFixed(3)) : 0;
}

export const calculatePowerFactor = (powerFactor, tp_fixedLoad, tp_energy, tp_lowVoltage, ch_powerFactorBonus, ch_powerFactorPenalty, totalKw) => {
    const sum = tp_fixedLoad + tp_energy + tp_lowVoltage;
    return powerFactor > totalKw ? -1 * (ch_powerFactorBonus * sum) : ch_powerFactorPenalty * sum;
};


export const getCriteria = (consumoBase, consumoIntermedio, consumoPunta, demandaPunta, diasFacturados, demandaBase, demandaIntermedia) => {
    const totalConsumption = consumoBase + consumoIntermedio + consumoPunta;
    const capacityA = demandaPunta;
    const capacityB = Math.ceil(totalConsumption / (24 * diasFacturados * 0.57));

    const distributionA = Math.max(demandaBase, demandaIntermedia, demandaPunta);
    const distributionB = capacityB;

    return {
        capacityA,
        capacityB,
        distributionA,
        distributionB
    };
};

export const buildModelPriceEnergy = (price, idConcepto) => {
    const obj = {
        IdConcepto: Number(idConcepto),
        Precio: price,
    };

    return { idConcepto: obj.IdConcepto, precio: obj.Precio };
};

export const toRadians = (angle) => {
    return (Math.PI / 180) * angle;
};

export const toDegrees = (angle) => {
    return angle * (180.0 / Math.PI);
};
