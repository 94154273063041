import { useMutation, useQuery } from "react-query";
import { ShowMessage } from "../../../utils/utils";
import axiosServiceV1 from "../../../apiService/axiosService";

export const getListUsers = async () => {
    return axiosServiceV1.get('user');
}

export const useGetListUsers = (isUpdated) => {
    return useQuery({
        queryKey: ['getListUsers', isUpdated],
        queryFn: async () => await getListUsers(),
        enabled: true,
    });
};

export const useSaveNewUser = () => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await axiosServiceV1.post('user', data);
            return response.data  || 'Ocurrió un error al intentar guardar el usuario'
        },
        onError: (error) => {
            //const message = error.response?.data?.error || 'Ocurrió un error al intentar guardar el usuario';
            //ShowMessage(message, 'error');
        },
        onSuccess: (data) => {
            if (data) {
                // ShowMessage('Se guardó correctamente', 'success');
            }
        },
    });
};

export const useSaveUpdateUser = () => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await axiosServiceV1.put('user', data);
            return response.data  || 'Ocurrió un error al intentar guardar el usuario'
        },
        onError: (error) => {
            //const message = error.response?.data?.error || 'Ocurrió un error al intentar guardar el usuario';
            //ShowMessage(message, 'error');
        },
        onSuccess: (data) => {
            if (data) {
                // ShowMessage('Se guardó correctamente', 'success');
            }
        },
    });
};


export const useDeleteUser = () => {
    return useMutation({
        mutationFn: (userId) => axiosServiceV1.delete(`user/${userId}`).then(response => response.data),
        onError: (error) => {
            // Muestra el mensaje de error desde el objeto de error
            ShowMessage(error.response?.data?.error || 'Ocurrió un error al intentar actualizar el estatus del usuario', 'error');
        },
        onSuccess: (data) => {
            if (data) {
                // ShowMessage('Usuario eliminado correctamente', 'success');
            }
        },
    });
};


export const getListProfile = async () => {
    return axiosServiceV1.get('profile');
}

export const useGetListProfile = (isUpdated) => {
    return useQuery({
        queryKey: ['getListProfile', isUpdated],
        queryFn: async () => await getListProfile(),
        enabled: true,
    });
};


export const useUpdateChangePassword = () => {
    return useMutation({
        mutationFn: (data) => axiosServiceV1.put('user/ChangePassword', data).then(response => response.data),
        onError: (error) => {
            ShowMessage(error.response?.data?.error || 'Ocurrió un error al intentar actualizar la contraseña del usuario', 'error');
        },
        onSuccess: (data) => {
            if (data) {
                // ShowMessage('Se guardó correctamente', 'success');
            }
        },
    });
};
