import { Grid, Typography } from "@mui/material"
import { TitleSection } from "../../../../components/Base/TitleSection"
import Select from "../../../../components/Select/Select";
import { DetailNode } from "./DetailNode";
import { DetailBattery } from "./DetailBattery";
import { Deductions } from "./Deductions";
import { useContext, useEffect, useState } from "react";
import { ProjectionsContext } from "../../../../utils/context/projectionsContext";

export const Projections = () => {
    const {state, selectNode, setSelectNode, disabled} = useContext(ProjectionsContext);
    const [optionsNodes, setOptionsNodes] = useState([]);

    const getDefaultValue = () =>{
        if(selectNode && disabled) return selectNode;
        return null
    }

    useEffect(() => {
        if (state && Array.isArray(state?.listNodes)) {
            const data = JSON.parse(JSON.stringify(state?.listNodes ?? []));
            const auxData = [];
            data.map((value) => {
                auxData.push({
                    value: value.nodeId,
                    label: value.node
                })
            });
            setOptionsNodes(auxData);
        }
    }, [state?.listNodes]);

    return (
        <Grid container marginTop={3}>
            <TitleSection title={"Proyección"} />
            <Grid item container >
                <Grid item xs={4} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Nodo</Typography>
                    <Select
                        options={optionsNodes}
                        onChange={(value) => { setSelectNode(value) }}
                        searchable={true}
                        isLoading={disabled ? !(disabled && selectNode) : false}
                        defaultValue={getDefaultValue()}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
            <DetailNode/>
            <DetailBattery/>
            <Deductions/>
        </Grid>
    )
}