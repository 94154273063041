import React, { useState, useEffect, useRef } from "react";
import {
  SelectListItem,
  Checkbox,
  SelectContainer,
  SelectHeader,
  SelectIcon,
  SelectList,
  SearchInput
} from "./styled.components";
import { FormHelperText } from "@mui/material";
import { Loader } from "../Base/Loader";

const Select = ({
  options,
  multiSelect = false,
  placeholder = "-",
  defaultValue,
  onChange,
  searchable = false, // Prop to enable search
  error,
  helperText,
  isLoading = false,
  disabled = false,
  style = {}
}) => {

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    defaultValue || (multiSelect ? [] : null)
  );
  const [searchTerm, setSearchTerm] = useState("");
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setSearchTerm(""); // Clear search term when the dropdown closes
    }
  }, [isOpen]);

  useEffect(() => {
    if(defaultValue){
      setSelectedOption(defaultValue);
    }
  }, [defaultValue])

  const handleSelect = (option) => {
    if (multiSelect) {
      if (selectedOption.includes(option.value)) {
        const newSelected = selectedOption.filter(
          (item) => item !== option.value
        );
        setSelectedOption(newSelected);
        onChange(newSelected);
      } else {
        const newSelected = [...selectedOption, option.value];
        setSelectedOption(newSelected);
        onChange(newSelected);
      }
    } else {
      setSelectedOption(option.value);
      setIsOpen(false);
      onChange(option.value);
    }
  };

  const isSelected = (option) =>
    multiSelect
      ? selectedOption.includes(option.value)
      : selectedOption === option.value;

  const renderSelected = () => {
    if (multiSelect) {
      const selectedLabels = options
        .filter((option) => selectedOption.includes(option.value))
        .map((option) => option.label);
      return selectedLabels.length ? selectedLabels.join(", ") : placeholder;
    }
    const selected = options.find((option) => option.value === selectedOption);
    return selected ? selected.label : placeholder;
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsOpen(!isOpen);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
 
  return (
    <SelectContainer 
      ref={selectRef} 
      style={{ backgroundColor: style.backgroundColor, minWidth: style.minWidth }}
      disabled={disabled}
      >
      <SelectHeader
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        aria-expanded={isOpen}
        style={{padding: style.padding}}
        error={error}
        disabled={disabled}
      >
        <div className="contentSelect">{(!isLoading) ? renderSelected() : <Loader/>}</div>
        <SelectIcon isOpen={isOpen} disabled={disabled}>
          <img src="/assets/icono_reg.svg" alt="dropdown icon" height="20px" />
        </SelectIcon>
      </SelectHeader>
      <FormHelperText style={{color: "#d32f2f"}}>{helperText}</FormHelperText>
      {isOpen && (
        <SelectList role="listbox">
          {searchable && (
            <SearchInput
              type="text"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
          {filteredOptions.map((option, index) => (
            <SelectListItem
              key={index}
              onClick={() => handleSelect(option)}
              role="option"
              aria-selected={isSelected(option)}
            >
              {multiSelect && (
                <Checkbox checked={isSelected(option)} readOnly />
              )}
              {option.label}
            </SelectListItem>
          ))}
        </SelectList>
      )}
    </SelectContainer>
  );
};

export default Select;
