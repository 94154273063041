import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';

const getBatteries = () => {
  return axiosServiceV1.get(`Batteries`);
};

export const useGetBatteries = () => {
  return useQuery({
      queryKey: ['batteries'],
      queryFn: () => getBatteries(),
      enabled: true
  });
};

const getBatteriesWithWarranties = () => {
  return axiosServiceV1.get(`Batteries/all-with-warranties`);
};

export const useGetBatteriesWithWarranties = () => {
  return useQuery({
    queryKey: ['batteries-and-warranties'],
    queryFn: getBatteriesWithWarranties,
    enabled: true,
  });
};

const getBatteriesById = (id) => {
  return axiosServiceV1.get(`Batteries/${id}`);
};

export const useGetBatteriesById = (id) => {
  return useQuery({
      queryKey: ['batterie'],
      queryFn: () => getBatteriesById(id),
      enabled: false
  });
};

const getTimeWarranty = () => {
  return axiosServiceV1.get(`General/relGarantiasBateriasTbl/idTiempo`);
};

export const useGetTimeWarranty = () => {
  return useQuery({
      queryKey: ['timewarranty'],
      queryFn: () => getTimeWarranty(),
      enabled: true
  });
};

const createBatteries = (data) => {
  return axiosServiceV1.post(`Batteries`, data);
}

export const useCreateBatteries = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar crear la batería', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              //ShowMessage('Se creo correctamente la batería', 'success');
          }

      },

      mutationFn: (data) => createBatteries(data).then(res => res).catch(err => null),
  });
};

const updataBatteries = (data, id) => {
  return axiosServiceV1.put(`Batteries/${id}`, data);
}

export const useUpdateBatteries = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar actualizar la batería', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              ShowMessage('Se actualizó la batería', 'success');
          }

      },

      mutationFn: ({data, id}) => updataBatteries(data, id).then(res => res).catch(err => null),
  });
};

const deleteBatteries = (id) => {
  return axiosServiceV1.delete(`Batteries/${id}`);
}

export const useDeleteBatteries = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar eliminar la batería', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          //ShowMessage('Se elimino la batería', 'success');
      },

      mutationFn: (id) => deleteBatteries(id).then(res => res).catch(err => null),
  });
};