import { GridOn, Refresh, Replay, FormatColorFill, FormatColorText, Colorize } from "@mui/icons-material"
import { Divider, Grid, IconButton } from "@mui/material"
import { ViewTable } from "./TablePlugin";
import { ViewFormatText } from "./FormatTextPlugin";
import { ViewColorPicker } from "./FormatColorPickerPlugin";
import { useState } from "react";

export const Toolbar = ({ editor }) => {
    const [moreTable, setMoreTable] = useState(false);

    return (
        <Grid container alignItems={"center"} columnSpacing={2}>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().undo().run()} disabled={!editor.can().undo()}>
                    <Replay className="iconmui-redo-undo" />
                </IconButton>
            </Grid>
            <Grid item>
                <IconButton onClick={() => editor.chain().focus().redo().run()} disabled={!editor.can().redo()}>
                    <Refresh className="iconmui-redo-undo" />
                </IconButton>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <ViewFormatText editor={editor} />
            </Grid>
            <Grid item className="color-picker">
                <ViewColorPicker
                    icon={<FormatColorText className="iconmui" />}
                    handleChangeColor={(color) => editor.chain().focus().setColor(color).run()}
                />
            </Grid>
            <Grid item className="color-picker">
                <ViewColorPicker
                    icon={<FormatColorFill className="iconmui" />}
                    handleChangeColor={(color) => editor.chain().focus().toggleHighlight({ color: color }).run()}
                />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <IconButton onClick={() =>{
                    editor
                        .chain()
                        .focus()
                        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                        .run()
                        setMoreTable(true)}}>
                    <GridOn className="iconmui" />
                </IconButton>
            </Grid>
            {moreTable &&
                <Grid item className="color-picker">
                    <ViewTable editor={editor} setMoreTable={setMoreTable}/>
                </Grid>
            }
        </Grid>
    )
}