import Axios from 'axios';
import { RUTA_API_BACK } from '../constans/index';
import { redirect } from "react-router-dom";
import { ShowMessage } from '../utils/utils';

const authRequestInterceptor = (config) => {
    config.headers.Accept = 'application/json';
    return config;
}

const axiosService = Axios.create({
    baseURL: RUTA_API_BACK,
});

// axios service for requests that do not require a 500 error message
const axiosServicev2 = Axios.create({
    baseURL: RUTA_API_BACK,
});

const configureInterceptor = (axiosInstance, enable500) => {
    axiosInstance.interceptors.request.use(authRequestInterceptor);
    axiosInstance.interceptors.response.use(
        ({ status, data }) => {
            if (status === 200) {
                return data;
            } else if (status === 201) {
                return data;
            } else if (status === 204) {
                return true;
            } else if (status === 404) {
                const message = data?.message || "No se encontraron resultados";
                ShowMessage(message, "error");
                return null;
            } else {
                if (status !== null) {
                    ShowMessage("Error desconocido. Favor de contactar a soporte.", "error");
                }
                return null;
            }
            return data;
        },
        (error) => {
            const { status, data } = error.response;
            if (status === 500) {
                const message = data?.message || "Hubo un error en el servidor, favor de contactar a soporte";
                if (enable500) {
                    ShowMessage(message, "error");
                }
            } else if (status === 401) {
                ShowMessage("Tu sesion a expirado favor de ingresar de nuevo", "error");
                setTimeout(() => {
                    window.location.href = "/auth/signin";
                }, 2000);
            } else if (status === 400) {
                ShowMessage("Ocurrió un error en los datos enviados", "error");
            } else if (status === 404) {
                const message = data?.message || "No se encontraron resultados";
                ShowMessage(message, "warning");
                return null;
            }

            return Promise.reject(error);
        }
    );
}

configureInterceptor(axiosService, true);
configureInterceptor(axiosServicev2, false);


export default axiosService;
export { axiosServicev2 };