import React from 'react';
import { Grid, Typography } from '@mui/material';
import { CustomBox, ContentHeader, DividerCustom } from './styled'; // Asegúrate de ajustar esta ruta
import { useNavigate } from 'react-router-dom';

const CustomTemplate = ({
  children,
  title,
  hasButtonBack = false,
}) => {

  const history = useNavigate();

  return (
    <>
      <CustomBox margin={4} mt={2}>
        <Grid container className='header' spacing={2}>
          <Grid item xs={6}>
            <ContentHeader>
              {hasButtonBack && (
                <img
                      src="/assets/icono_atras.svg"
                      alt="icon back"
                      width="32px"
                      height="32px"
                      onClick={() => history(-1)}
                    />
              )}
              <Typography className="title">{title}</Typography>
            </ContentHeader>
            {/* Añade el <hr> con estilo justo debajo del título */}
            
          </Grid>
          <Grid item xs={6}>
            {/* Botón eliminado */}
          </Grid>
        </Grid>
        <DividerCustom /> {/* Reemplaza el <hr> por el componente styled */}
        <br /> {/* Salto de línea después del <hr> */}
        <Grid container className='main-container'>
          {children}
        </Grid>
      </CustomBox>
    </>
  );
};

export default CustomTemplate;