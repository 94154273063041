import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';

const getExchangeRate = () => {
  return axiosServiceV1.get(`Projection/exchange-rate`);
};

export const useGetExchangeRate = () => {
  return useQuery({
      queryKey: ['exhange-rate'],
      queryFn: () => getExchangeRate(),
      enabled: true
  });
};

const getExchangeRateBanxico = (data) => {
  return axiosServiceV1.get(`projection/exchange-rate/banxico?initialDate=${data.startDate}&finalDate=${data.endDate}`);
};

export const useExchangeRateBanxico = (data) => {
  return useQuery({
      queryKey: ['exhange-rate-banxico'],
      queryFn: () => getExchangeRateBanxico(data),
      enabled: false
  });
};

const applyConfiguration = (data) => {
    return axiosServiceV1.post(`Projection/exchange-rate`, data);
  }
  
  export const useApplyConfiguration = () => {
    return useMutation({
        onError: (_, __, ___) => {
            ShowMessage('Ocurrió un error al intentar aplicar la configuración', 'error');
        },
        onSuccess: (data, dataDto, _) => {
            if (data) {
                ShowMessage('Se aplico la configuración', 'success');
            }
  
        },
  
        mutationFn: (data) => applyConfiguration(data).then(res => res).catch(err => null),
    });
  };
