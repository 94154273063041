import { useState, useEffect } from "react";
import StoreUser from "../storeUser";

export const useChangeUser = () => {
    const storeUser = new StoreUser();

    const [dataAuth, setDataAuth] = useState();
    const [token, setToken] = useState();
    const [idUsuario, setIdUsuario] = useState();
    const dataAuth$ = storeUser.infoUser;

    useEffect(() => {
        dataAuth$.subscribe((data) => setDataAuth(data));
    }, []);

    useEffect(() => {
        if (dataAuth) {
            setToken(dataAuth.token);
            setIdUsuario(dataAuth?.userData.idUsuario);
        }
    }, [dataAuth])

    return { userData: dataAuth?.userData, token, idUsuario }
}