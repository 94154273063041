import { Grid, Icon } from "@mui/material";
import styled from "styled-components";

export const StyledIcon = styled(Icon)`
  width: auto !important;
  height: auto !important;
  svg{
    width: 25px;
    height: 25px;
    border-radius: 5px;

    rect{
      fill: ${props => props.bgColor ?? "#2756F3"};
    }
  }
`;

export const StyledIconMui = styled(Icon).withConfig({
  shouldForwardProp: (prop) => prop !== 'bgColor'
})`
  width: auto !important;
  height: auto !important;
  svg {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: ${props => props.bgColor ?? "#2756F3"};
    color: white;
  }
`;

export const StyledEditorDisabled = styled(Grid)`
  .editorContent{
    background-color: #e8eefe;
    border-color: #e8eefe;
    .ProseMirror{
      height: 30vh;
      min-height: 30vh;
    }
  }
`;