import React, { memo, useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const GenericTabs = ({ tabs, tabvalue, components, setUpdateTab }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setUpdateTab(newValue);
  };

  useEffect(() => {
    setValue(tabvalue);
  }, [tabvalue]);

  return (
    <Box>
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <Tab label={tab} key={index} />
        ))}
      </Tabs>
      {components.map((component, index) => (
        <Box key={index} hidden={value !== index} style={{ marginTop: 16 }}>
          {value === index && (component)}
        </Box>
      ))}
    </Box>
  );
};

export const TabsGeneric = memo (GenericTabs);
