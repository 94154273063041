import { ContentTable } from "./styled.components"

export const InvoicePeriod = ({columns, data}) => {
    return (
        <ContentTable>
            <thead>
                <tr>
                    {columns.map((value, index) => (
                        <th key={index}>{value.title}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data?.map((value, index) => (
                    <tr key={index}>
                        <th>{value.startDate}</th>
                        <th>{value.endDate}</th>
                        <th>{value.billedDays}</th>
                    </tr>
                ))}
            </tbody>
        </ContentTable>
    )
}