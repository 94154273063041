import { Dialog, Grid } from '@mui/material';
import styled from 'styled-components';

export const Root = styled.main`
  height: inherit;
  padding: 32px 24px;
`;

export const ContentPage = styled.section`
  background-color: white;
  display: grid;
  gap: 24px;
  height: 100%;
  align-content: start;
  border-radius: 16px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
`;

export const Header = styled.header`
  text-align: start;
  padding: 32px;
  position: relative;
  color: ${props => props.theme.palette.primary.main};
  > hr{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
    border: none;
    outline: none;
    height: 2px;
    background-color: ${props => props.theme.palette.grey.main};
  }
`;

export const BodyContent = styled.div`
  padding: 24px;
  display: grid;
  gap: 8px;

  >button{
    justify-self: end;
  }
`;

export const DialogMui = styled(Dialog)`
.MuiPaper-root {
  width: ${props => props.width ?? "40%"} !important;
  }
`;

//TariffSelectModal
export const ModalRoot = styled.div`
  position: fixed; /* Cambiado de absolute a fixed para asegurar que siempre esté en el centro de la pantalla, no solo del contenedor padre */
  top: 50%;
  left: 50%;
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '700px'};
  min-height: 200px;
  background-color: white;
  border-radius: 16px;
  padding: 20px 16px 30px;
  display: grid;
  gap: 16px;

  transform: translate(-50%, -50%); /* Añadido para centrar el modal */

  > h3 {
    text-align: center;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 22px 22px;
  gap: 24px;
`;

export const FooterActions = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 90px;
  gap: 24px;
`;

export const StyledIconStatus = styled(Grid)`
  color: ${props => props.iconcolor} !important;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;

export const UploaderContainer = styled.div`
  width: 100%;
`;
