import { Box, Grid, Typography } from "@mui/material"
import { TablePay } from "./components/TablePay"
import { Data } from "./Data"
import { ViewSection } from "../ViewSection"
import { Divider, StyledNameContent, StyledTextField } from "../styled.components";
import { BreakdownPay } from "../BreakdownPay";
import { StyledPricePay } from "./styled.components";
import { useEffect, useState } from "react";
import { CustomModal } from "../../../../components/Modal/CustomModal";

export const PricePayContainer = ({ data, isPeriodType, deleteprice, updateData, setDeletePrice, setAddInvoice, isVisibility }) => {
    const [item, setItem] = useState(1);
    const [quotationName, setQuotationName] = useState("");
    const [showQuotationtmodal, setShowQuotationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const MAXLENGHT = 20;
    const [errorName, setErrorName] = useState({
        error: false,
        message: ""
    });

    const [datapricepay, setDataPricePay] = useState([{
        titles: "Conceptos",
        color: "",
        data: [
            {
                fixedCharge: "Cargo fijo",
                energy: "Energia",
                lowVoltage2p: "2% Baja tension",
                powerFactor: "Bonificacion factor de potencia",
                subtotal: "Subtotal",
                tax: "IVA 16%",
                billingPeriod: "Facturacion del periodo",
                lightingFee: "Derecho de alumbrado publico",
                totalAmount: "TOTAL"
            },
        ]
    }]);
    const [disabled, setDisabled] = useState(false);

    const generatePastelColor = () => {
        let R = Math.floor((Math.random() * 127) + 127);
        let G = Math.floor((Math.random() * 127) + 127);
        let B = Math.floor((Math.random() * 127) + 127);

        let rgb = (R << 16) + (G << 8) + B;
        return `#${rgb.toString(16)}`;
    }

    const handleAlphaNumericInput = (value) => {
        var regex = /[A-ZáÁéÉìÌíÍóÓúÚñÑa-z0-9_\-(). ]+$/;
        if (!RegExp(regex).test(value)) {
            return false
        }
        return true
    };

    const handleAdd = () => {
        setShowQuotationModal(true);
        setQuotationName("");
        setErrorName({ error: false, message: "" });
    };

    const handleSaveQuotationName = () => {
        const trimmedQuotationName = quotationName.trim();
        const isQuotationNameExists = datapricepay.some(item => item.quotationName === trimmedQuotationName);

        if (isQuotationNameExists) {
            setErrorName({ error: true, message: "Ya existe una cotización con el mismo nombre" });
            return;
        }

        if (trimmedQuotationName.length > 0 && handleAlphaNumericInput(trimmedQuotationName)) {
            setErrorName({ error: false, message: "" });
        } else if (trimmedQuotationName.length === 0) {
            setErrorName({ error: true, message: "Nombre requerido" });
            return;
        } else if (!handleAlphaNumericInput(trimmedQuotationName)) {
            setErrorName({ error: true, message: "Solo se permiten caracteres numéricos" });
            return;
        }
    
        setShowQuotationModal(false);
        handleAddQuotation();
    };

    const handleAddQuotation = () => {
        const lengthdatapricepay = datapricepay.length;
        const datapricedefault = {
            fixedCharge: 0,
            energy: 0,
            lowVoltage2p: 0,
            powerFactor: 0,
            subtotal: 0,
            tax: 0,
            billingPeriod: 0,
            lightingFee: 0,
            totalAmount: 0, 
            periodTypeId: 0,
        }

        const consumption = {
            consumptionId: 0,
            receiptDetailId: 0,
            totalConsumption: 0,
            monthlyMinimum: 0,
            fpBonus: 0,
            fpPenalty: 0,
            averagePrice: 0,
            loadFactor: 0
        }

        const criteria = {
            consumptionId: 0,
            billDetailId: 0,
            capacityA: 0,
            capacityB: 0,
            distributionA: 0,
            distributionB: 0
        }

        const details = {
            receiptDetailId: 0,
            receiptId: 0,
            tariffTypeId: 0,
            periodTypeId: 0,
            calculationTypeId: 2,
            startDate: '',
            endDate: '',
            billedDays: 0,
            baseDemand: 0,
            intermediateDemand: 0,
            peakDemand: 0,
            baseConsumption: 0,
            intermediateConsumption: 0,
            peakConsumption: 0,
            reactivePower: 0,
            measurement: 0,
            kwmaxAm: 0,
            powerFactor: 0,
            criteria: [criteria],
            consumption: [consumption],
            consumptionId: 0,
            criteriaId: 0,
            totalKw: 0,
            lightingFee: 0,
            listEnergyCost: []
        }

        const pricepayfull = [];
        const datailsfull = [];

        /*data[0]?.summaryAmount.map((summary, index) => {
            let newPricePay = {
              ...datapricedefault,
              ...summary,
              amountBreakdownId: 0,
            };
            pricepayfull.push(newPricePay);
          });*/
          updateData.datafull[0]?.data.map((summary, index) => {
            let newPricePay = {
              ...datapricedefault,
              ...summary,
              amountBreakdownId: 0,
            };
            pricepayfull.push(newPricePay);
          });

          //data[0]?.details.map((value, index) => {
            updateData.datafull[0]?.details.map((value, index) => {
                //console.log("value", value);
                let newDetails = {
                    ...details,
                    periodTypeId: value.periodTypeId,
                    billedDays: JSON.parse(JSON.stringify(value.billedDays)),
                    tariffTypeId: value.tariffTypeId,
                    receiptId: value.receiptId,
                    measurement: value.measurement,
                    startDate: value.startDate,
                    endDate: value.endDate,
                    consumptionId: value.consumption[0].consumptionId,
                    criteriaId: value.criteria[0].consumptionId,
                    baseConsumption: value.baseConsumption,
                    intermediateConsumption: value.intermediateConsumption,
                    peakConsumption: value.peakConsumption,
                    baseDemand: value.baseDemand,
                    intermediateDemand: value.intermediateDemand,
                    peakDemand: value.peakDemand,
                    reactivePower: value.reactivePower,
                    kwmaxAm: value.kwmaxAm,
                    powerFactor: value.powerFactor,
                    lightingFee: value.lightingFee,
                    totalKw: value.totalKw,
    
                    criteria: value.criteria.map((crit) => ({
                        ...criteria,
                        ...crit,
                        consumptionId: 0
                    })),
                    consumption: value.consumption.map((cons) => ({
                        ...consumption,
                        ...cons,
                        consumptionId: 0
                    })),
    
                    //listEnergyCost: value.energyCosts.map((cost) => ({
                    listEnergyCost: value.listEnergyCost.map((cost) => ({
                        ...cost,
                        energyCostId: 0
                    })),
    
                };
                datailsfull.push(newDetails);
            });
        
        const dataaux = {
            idCotizacion: lengthdatapricepay,
            quotationName: quotationName,
            details:JSON.parse(JSON.stringify(datailsfull)),
            titles: quotationName,
            color: generatePastelColor(),
            data: JSON.parse(JSON.stringify(pricepayfull)),
        }
        setItem(item + 1);
        //const auxdata = [...datapricepay];
        const auxdata = JSON.parse(JSON.stringify(datapricepay));
        auxdata.push(dataaux);
        setDataPricePay(auxdata);
        setAddInvoice(auxdata.slice(1));
    }

    /*useEffect(() => {
        if (data.length > 0 && datapricepay.length === 1) {
            //let aux = [...datapricepay];
            
            let aux = JSON.parse(JSON.stringify(datapricepay));
            aux[0].color = generatePastelColor();
            aux.push({
                idCotizacion: data[0]?.quotationId,
                quotationName: "Documento original",
                details: JSON.parse(JSON.stringify(data[0]?.details)),
                titles: "Documento original",
                color: generatePastelColor(),
                data: JSON.parse(JSON.stringify(data[0]?.summaryAmount)),
            });
            setDataPricePay(aux);
            setAddInvoice(aux.slice(1));
        }
    }, []);*/

    useEffect(() => {
        if (data.length > 0 && datapricepay.length === 1) {
            //let aux = [...datapricepay];
            const aux = JSON.parse(JSON.stringify(datapricepay));
            aux[0].color = generatePastelColor();
            data.forEach((item, index) => {
                aux.push({
                    idCotizacion: item?.quotationId,
                    quotationName: item.quotationName || "Documento original",
                    details: JSON.parse(JSON.stringify(item?.details)),
                    titles: item.quotationName || "Documento original",
                    color: generatePastelColor(),
                    data: item?.summaryAmount//JSON.parse(JSON.stringify(data[0]?.summaryAmount)),
                });
            });

            setDataPricePay(aux);
            setAddInvoice(aux.slice(1));
        }
    }, []);

    useEffect(() => {
        if (updateData) {
            const auxprice = [...datapricepay];
            //const auxprice = JSON.parse(JSON.stringify(datapricepay));
            auxprice[updateData.numQuotation + 1] = updateData.datafull[updateData.numQuotation];
            setDataPricePay(auxprice);
            setAddInvoice(auxprice.slice(1));
        }
    }, [updateData]);

    useEffect(() => {
        if (datapricepay?.length === 5) {
            setDisabled(true);
        } else if (datapricepay?.length < 5) {
            setDisabled(false);
        }
    }, [datapricepay]);

    useEffect(() => {
        if (deleteprice) {
            const deletepay = datapricepay.filter(x => x?.titles !== datapricepay[deleteprice + 1]?.titles);
            setDataPricePay(deletepay);
            setAddInvoice(deletepay.slice(1));
        }
        return () => setDeletePrice(null);
    }, [deleteprice]);

    return (
        <>
            <ViewSection
                title={(<Typography variant="subtitle1">Desglose de importe a pagar $</Typography>)}
                divider={(<Divider />)}
            >
                <Grid container>
                    {!isVisibility &&
                        <Grid item xs={12} textAlign={"right"} marginBottom={5}>
                            <BreakdownPay disabled={disabled} handleAdd={handleAdd} />
                        </Grid>
                    }
                    <Grid item container sx={{ overflowX: "scroll", flexWrap: "nowrap" }}>
                        {datapricepay?.map((value, index) => (
                            <StyledPricePay key={index} item>
                                <TablePay key={index} column={index} title={value?.titles} data={value?.data} color={value?.color} />
                            </StyledPricePay>
                        ))}
                    </Grid>
                </Grid>
            </ViewSection>

            {showQuotationtmodal && <CustomModal
                title={"Nombre cotización"}
                open={showQuotationtmodal}
                isLoading={isLoading}
                labelAccept={"Guardar"}
                close={() => setShowQuotationModal(false)}
                onSuccess={handleSaveQuotationName}
                maxWidth="sm"
            >
                <StyledNameContent container>
                    <Grid item xs={12}>
                        <Box mt={2}>
                            <Typography variant="subtitle2" gutterBottom>Ingresa nombre</Typography>
                            <StyledTextField
                                fullWidth
                                value={quotationName}
                                onChange={(e) => {
                                    setQuotationName(e.target.value);
                                }}
                                inputProps={{
                                    maxLength: MAXLENGHT,
                                }}
                                error={errorName.error}
                            />
                            {errorName.error && (
                                <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                    {errorName.message}
                                </Typography>
                            )}
                        </Box>
                    </Grid>
                </StyledNameContent>
            </CustomModal>}
        </>
    )
}