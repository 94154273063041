import { TextField } from "@mui/material";
import styled from "styled-components";

export const InputStyles = styled(TextField)`
  background-color: white;
  border-radius: 4px; /* Ajusta el borde redondeado aquí */
  overflow: hidden; /* Asegúrate de que el contenido se ajuste al borde redondeado */

  & .MuiInputBase-root {
    border-radius: 4px; /* Asegúrate de que el input base también tenga el borde redondeado */
    height: 43px;
  }

  & .MuiInputBase-input {
    color: black;
  }

  & .MuiInputLabel-root {
    color: black;
  }

  &.Mui-disabled {
    color: gray;
    background-color: #f0f0f0;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #d3d6dd; /* Ajusta el color del borde */
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde al pasar el mouse */
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2756f3; /* Color del borde cuando está enfocado */
  }
`;

export const TextReadOnlyStyles = styled.div`
  background-color: #e8eefe;
  height: 43px;
  border-radius: 5px;
  text-align: justify;
  align-content: center;
  padding-left: 20px;
`;