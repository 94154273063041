import { createContext, useState } from 'react';

const ProjectionsContext = createContext();

const ProjectionsProvider =  (props) => {
    const [state, setState] = useState(null);
    const [node, setNode] = useState([]);
    const [selectNode, setSelectNode] = useState(null);
    const [selectBatterie, setSelectBatterie] = useState(null);
    const [selectedNodeDetail, setSelectedNodeDetail] = useState(null);

    const handleSave = async () => {
       // if(state && state?.)
    }
    const [selectIdConfig, setSelectIdConfig] = useState(null);
    const [selectCalcNode, setSelectCalcNode] = useState(null);
    const [disabled, setDisabled] = useState(false);

    const handleValues = (value) => {
        setState(value);
    }

    const reset = () => {
        setState(null);
        setNode([]);
        setSelectBatterie(null);
        setSelectNode(null);
        setSelectedNodeDetail(null);
        setSelectIdConfig(null);
        setSelectCalcNode(null);
    }

    const initialState = {
        state, 
        node, 
        selectNode, 
        selectedNodeDetail,
        selectBatterie,
        selectIdConfig,
        selectCalcNode,
        disabled,
        setDisabled,
        setSelectCalcNode,
        setSelectIdConfig,
        setSelectNode, 
        setSelectedNodeDetail,
        setSelectBatterie, 
        handleValues, 
        setNode,
        reset,
    }

    return(
        <ProjectionsContext.Provider value={initialState}>
            {props.children}
        </ProjectionsContext.Provider>
    )
}

export { ProjectionsContext, ProjectionsProvider };