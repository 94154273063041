import React, { useState } from 'react';
import { TextField, Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Root, LoginBox, Logo, StyledTextField, StyledButton } from './styled.components';
import { useLogin } from './Api/LoginApi';
import StoreUser from '../../storeUser';
import { ReactComponent as LogoSvg } from './../../assets/logo3_.svg';
import { ReactComponent as InicioSvg } from './../../assets/logo2_.svg';
import { ShowMessage } from '../../utils/utils';

const Login = () => {
  const [credentials, setCredentials] = useState({
    userName: "",
    password: "",
  });

 
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const storeUser = new StoreUser();
  const login = useLogin();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const keyEventEnter = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!credentials.userName || !credentials.password) {
      ShowMessage("El campo de usuario y contraseña son requeridos", "error");
      return;
    }
    if (!validateEmail(credentials.userName)) {
      ShowMessage("El campo de usuario debe ser un correo electrónico válido", "error");
      return;
    }
    const data = credentials;
    await login.mutateAsync(data).then((res) => {
      if (res) {
        const userData = {
          token: res.jwt,
          userData: {
            userId: res.userId,
            userName: res.Username,
            name: res.name,
            email: res.email,
            token: res.token,
            refreshToken: res.refreshToken,
            initial: res.initial,
          },
        }
        storeUser.addUserData(userData);
        window.location.replace("/");
      }
    });
    await login.reset();
  };

  return (
    <Root>
      <LoginBox>
        <Logo>
          <LogoSvg alt="logo" style={{ marginTop: '-40px' }} />
        </Logo>
        <Typography variant="h6" style={{ textAlign: 'left', width: '80%', fontSize:'18px' }}>Usuario</Typography>
        <StyledTextField>
          <TextField
            variant="outlined"
            fullWidth
            onKeyDown={keyEventEnter}
            value={credentials.userName}
            onChange={(e) => setCredentials({ ...credentials, userName: e.target.value })}
            InputProps={{
              style: { color: 'white' },
            }}
          />
        </StyledTextField>
        <Typography variant="h6" style={{ textAlign: 'left', width: '80%', fontSize:'18px' }}>Contraseña</Typography>
        <StyledTextField>
          <TextField
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
            fullWidth
            onKeyDown={keyEventEnter}
            variant="outlined"
            InputProps={{
              style: { color: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    style={{ color: 'white' }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </StyledTextField>
        <StyledButton>
          <Button variant="contained" color="primary" onClick={handleLogin} >
            <InicioSvg alt="logo" />
            Iniciar sesión
          </Button>
        </StyledButton>
      </LoginBox>
    </Root>
  );
};

export default Login;
