import React, { useEffect, useRef, useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Select,
    MenuItem,
    Box,
    Typography,
    IconButton,
    Grid,
    TextField,
    InputAdornment,
    Pagination,
    PaginationItem,
    Button,
} from "@mui/material";
import { styled as styledMaterial } from "@mui/material/styles";
import styled from "styled-components";
import { ReactComponent as IconoReg } from '../../icons/icono_reg.svg';
import { ReactComponent as IconoNext } from '../../icons/icono_sig.svg';
import { Warning } from "@mui/icons-material";

const StyledTableContainer = styledMaterial(TableContainer)(({ theme }) => ({
    borderRadius: "10px",
    overflow: "hidden",
    border: "1px solid #3959A5",
    backgroundColor: "white",
}));

const CustomTableCell = styledMaterial(TableCell)(({ theme }) => ({
    backgroundColor: "#3959A5",
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
}));

const CustomTableCellBody = styledMaterial(TableCell)(({ theme }) => ({
    textAlign: "center",
    padding: "8px",
    borderBottom: `1px solid #496FEF`,
    width: "11%",
}));


const TitleTableCell = styledMaterial(TableCell)`
  text-align: center;
  font-weight: bold;
  background-color: #3959A5;
  color: white;
  position: relative;
`;


const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; // Asegúrate de que el elemento tenga un contexto de posición

    .validation-info {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        color: #ff922d;
    }

    .title {
        text-align: center;
        flex: 1; // Permite que el título ocupe el espacio disponible
        margin-left: 40px; // Espacio para el ícono y el texto de validación
        font-size: 0.875rem;
        color: #fff;
    }
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  bottom: 0px;
  right: 10px;
`;

const FilterInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  > img {
    position: absolute;
    right: 270px;
  }
`;

const FilterInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  width: 100%;
  min-width: 100px;
  max-width: 300px;
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 40px;
`;

const PaginationContainer = styled(Box)`
  display: flex;
  justify-items: start;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
`;

const PaginationControls = styled(Box)`
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;

  > button {
    &:disabled {
      > img {
        opacity: 0.4;
      }
    }
  }
`;

const RecordsInfo = styled(Typography).attrs({ component: 'div' })`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  flex: 1;
`;

const StyledSelect = styled(Select)`
  color: white;
  background-color: #3959A5;
  padding: 2px;
  border-radius: 4px;

  .MuiSelect-select {
    padding: 0px 4px;
    color: white;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: #3959A5;
  }

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: #3959A5;
  }

  .MuiSvgIcon-root {
    color: white;
  }

  .MuiMenuItem-root {
    color: black;
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: #3959A5;
    color: white;
  }
`;

const StyledTextField = styled(TextField)(({ theme }) => ({

    '& .MuiOutlinedInput-root': {
        padding: 0,
        width: '75%',
        margin: '0 auto',
    },
    '& .MuiInputBase-input': {
        padding: '4px 14px',
    },
}));

const CustomButton = styled(Button)`
  border: 1px solid #3959A5 !important;
  background-color: #ffffff !important;
  color: #3959A5 !important;
  font-size: 14px !important;
  padding: 4px 10px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
`;

const DataTableEditable = ({
    columns,
    data,
    adjustColumn,
    title,
    filterable = false,
    customBtn,
    onDownloadClick,
    pageSize,
    onCellValueChange,
    editableColumns,
    isConfirmSave,
    setIsConfirmSave,
    setLocalDetailsTariffProcess,
    idDivition,
    isProcessedDivition,
    onOpenConfirmModal,
    divisionId
}) => {
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(pageSize || 5);
    const [filter, setFilter] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState(data);
    const [modifiedCells, setModifiedCells] = useState(new Map());
    const [currentEditingDivisionId, setEditingDivisionId] = useState(null);

    useEffect(() => {
        setEditedData(data);
    }, [data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleFilterChange = (search) => {
        setFilter(search);
    };

    const handleSaveClick = () => {
        setEditingDivisionId(idDivition);
        if (isEditing) {
            // Muestra el modal de confirmación si estamos en modo de edición
            if (modifiedCells.size > 0 && Array.from(modifiedCells.values()).some(set => set.size > 0)) {
                // Si hay celdas modificadas, muestra el modal de confirmación
                onOpenConfirmModal(idDivition);
            }
            else {
                setIsEditing(false);
            }
        } else {
            setIsEditing(true);
        }
    };

    useEffect(() => {
        if (isConfirmSave && divisionId === currentEditingDivisionId) {
            // Realizar acciones solo si la confirmación es para la división actual
            handleSaveData(divisionId);
            setIsConfirmSave(false);
        }
    }, [isConfirmSave, divisionId, currentEditingDivisionId]);

    const handleSaveData = (id) => {
        if (isEditing) {
            // Crear un array para los detalles modificados
            const modifiedDetails = [];

            // Recorrer las celdas modificadas
            modifiedCells.forEach((columns, rowId) => {
                columns.forEach((columnId) => {
                    const row = editedData.find(row => row.id === rowId);
                    if (row) {
                        let columValue = 0;
                        switch (columnId) {
                            case 'transmition':
                                columValue = row.transmitionId;
                                break;
                            case 'distribution':
                                columValue = row.distributionId;
                                break;
                            case 'cenace':
                                columValue = row.cenaceId;
                                break;
                            case 'basic':
                                columValue = row.basicId;
                                break;
                            case 'mem':
                                columValue = row.memId;
                                break;
                            case 'energy':
                                columValue = row.energyId;
                                break;
                            case 'capacity':
                                columValue = row.capacityId;
                                break;
                            default:
                                columValue = 0;
                                break;
                        }
                        // Añadir los detalles modificados al array
                        modifiedDetails.push({
                            categoryId: row.categoryId,
                            unitId: row.unitId,
                            valueId: columValue,
                            value: Number(row[columnId])
                        });
                    }
                });
            });

            if (onCellValueChange) {
                onCellValueChange(modifiedDetails);
            }

            // Reiniciar el rastreador de celdas modificadas
            setModifiedCells(new Map());
        }

        // Cambiar el estado de edición
        setIsEditing(!isEditing);
    };

    const handleInputChange = (id, columnId, value, unitId, categoryId, rowFile) => {
        const pattern = /^\d{0,4}(?:\.\d{0,4})?$/;
        if (pattern.test(value) || value === "") {
            setEditedData(prevData =>
                prevData.map(row =>
                    row.id === id ? { ...row, [columnId]: value } : row
                )
            );

            const columnNumericId = idColumTable(columnId, rowFile);
    
            setModifiedCells(prev => {
                const updated = new Map(prev);
    
                // Solo añadir la columna si el valor es mayor a 0
                if (parseFloat(value) > 0) {
                    if (!updated.has(id)) {
                        updated.set(id, new Set());
                    }
                    updated.get(id).add(columnId);
                } else {
                    // Si el valor es 0 o vacío, removemos la columna de modificadas
                    if (updated.has(id)) {
                        updated.get(id).delete(columnId);
                        if (updated.get(id).size === 0) {
                            updated.delete(id);
                        }
                    }
                }
    
                return updated;
            });
    
            setLocalDetailsTariffProcess(prevLocalDetails => {
                return prevLocalDetails.map(divition => {
                    if (divition.idDivition === idDivition) {
                        return {
                            ...divition,
                            tariffs: divition.tariffs.map(tariff => {
                                if (tariff.unitId === unitId && tariff.categoryId === categoryId) {
                                    // Actualizar el detalle correspondiente
                                    const updatedDetails = tariff.details.map(detail => {
                                        if (detail.valueId === columnNumericId) {
                                            return {
                                                ...detail,
                                                value: parseFloat(value) || 0, // Actualizar el valor
                                                isProcessed: parseFloat(value) > 0 // Actualizar isProcessed basado en el valor
                                            };
                                        }
                                        return detail;
                                    });

                                    return {
                                        ...tariff,
                                        [columnId]: value,
                                        details: updatedDetails // Asignar los detalles actualizados
                                    };
                                }
                                return tariff;
                            })
                        };
                    }
                    return divition;
                });
            });
        }
    };

    const filteredData = editedData.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(filter.toLowerCase())
        );
    });

    const paginatedData = (filterable ? filteredData : editedData).slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    const isWideCell = (columnId) => {
        return adjustColumn.includes(columnId);
    };

    const isEditableCell = (columnId) => {
        return editableColumns.includes(columnId);
    };

    const idColumTable = (columnId, row) => {
        let columValue = 0;
        switch (columnId) {
            case 'transmition':
                columValue = row.transmitionId;
                break;
            case 'distribution':
                columValue = row.distributionId;
                break;
            case 'cenace':
                columValue = row.cenaceId;
                break;
            case 'basic':
                columValue = row.basicId;
                break;
            case 'mem':
                columValue = row.memId;
                break;
            case 'energy':
                columValue = row.energyId;
                break;
            case 'capacity':
                columValue = row.capacityId;
                break;
            default:
                columValue = 0;
                break;
        }

        return columValue;
    };

    const totalPages = Math.ceil((filterable ? filteredData.length : editedData.length) / rowsPerPage);

    const formatCellValue = (value) => {
        return value === 0 || value === '0' || value === null || value === '' ? '-' : value;
    };

    return (
        <div>
            <Grid container style={{ padding: "10px 0" }}>
                <Grid item xs={(customBtn ? 6 : 12)} container justifyContent={(customBtn ? "left" : "right")}>
                    {filterable && (
                        <TextField
                            placeholder={"Buscar"}
                            sx={{ m: 1, width: "25ch", margin: "0" }}
                            size="small"
                            value={filter}
                            onChange={(e) => handleFilterChange(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img
                                            src="/assets/icono_lupa.svg"
                                            alt="icon search"
                                            height="24px"
                                            width="24px"
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )}
                </Grid>
                {customBtn}
            </Grid>
            <StyledTableContainer>
                <Table>
                    <TableHead>
                        {title && (
                            <TableRow>
                                <TitleTableCell colSpan={columns.length}>
                                    <TitleWrapper>
                                        {isProcessedDivition && (
                                            <div className="validation-info">
                                                <Warning style={{ color: '#ff922d', marginRight: '8px' }} />
                                                <span>La tarifa requiere validación</span>
                                            </div>
                                        )}
                                        <div className="title">{title}</div>
                                    </TitleWrapper>
                                    <IconButtonWrapper>
                                        <IconButton color="primary" onClick={onDownloadClick}>
                                            <img src="/assets/icono_descargar_marco.svg" alt="icon download" height="30px" width="30px" />
                                        </IconButton>
                                        <CustomButton
                                            variant="outlined"
                                            onClick={handleSaveClick}
                                        >
                                            {isEditing ? "Guardar" : "Editar"}
                                        </CustomButton>
                                    </IconButtonWrapper>
                                </TitleTableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            {columns.map((column) => (
                                <CustomTableCell key={column.id}>
                                    {column.label}
                                </CustomTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row) => (
                            <TableRow key={row.id}>
                                {columns.map((column) => (
                                    <CustomTableCellBody
                                        key={column.id}
                                        sx={isWideCell(column.id) ? { width: "200px" } : {}}
                                    >
                                        {isEditing ? (
                                            isEditableCell(column.id) ? (
                                                idColumTable(column.id, row) > 0 ? (
                                                    <StyledTextField
                                                        value={row[column.id]}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                row.id,
                                                                column.id,
                                                                e.target.value,
                                                                row.unitId,
                                                                row.categoryId,
                                                                row
                                                            )
                                                        }
                                                        size="small"
                                                        fullWidth
                                                    />
                                                ) : (
                                                    formatCellValue(row[column.id])
                                                )
                                            ) : (
                                                formatCellValue(row[column.id])
                                            )
                                        ) : (
                                            idColumTable(column.id, row) ? (
                                                row[column.id] > 0 ? (
                                                    formatCellValue(row[column.id])
                                                ) : (
                                                    'x'
                                                )
                                            ) : (
                                                formatCellValue(row[column.id])
                                            )
                                        )}
                                    </CustomTableCellBody>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            <PaginationContainer>
                <PaginationControls>
                    <RecordsInfo>
                        {`Mostrar `}
                        <StyledSelect
                            value={rowsPerPage}
                            onChange={handleChangeRowsPerPage}
                            displayEmpty
                        >
                            {[5, 10, 25].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                        {` registros`}
                    </RecordsInfo>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                        <Pagination
                            count={totalPages}
                            page={page}
                            onChange={handleChangePage}
                            color="primary"
                            shape="rounded"
                            renderItem={(item) => (
                                <PaginationItem
                                    components={{
                                        previous: (props) => <IconoReg {...props} width="12px" height="12px" />,
                                        next: (props) => <IconoNext {...props} width="12px" height="12px" />,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </div>
                    <div style={{ flex: 1 }}></div>
                </PaginationControls>
            </PaginationContainer>
        </div>
    );
};

export default DataTableEditable;
