import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import {
  ContentActions,
  ContentListTables,
  StyledDelete,
} from "./styled.components";
import Select from "../../components/Select/Select";
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import { useGeneralList, useDetailTariffProcess, useDownloadTariffProcessingFile, useUpdateTariffProcessDetail, updateAuditProcessedTariff, useUpdateAuditProcessedTariff, useGetAuditProcessedTariff } from "./api/TariffProcessDetailApi";
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate";
import DataTableEditable from "../../components/DataTable/DataTableEditable";
import { ShowMessage } from "../../utils/utils";
import { Button, Dialog, Grid } from "@mui/material";
import { Warning } from "@mui/icons-material";
import { Loader } from "../../components/Base/Loader";

const options = [
  { label: "Baja California", value: 1 },
  { label: "Baja California Sur", value: 2 },
  { label: "Bajío", value: 3 },
  { label: "Centro Occidente", value: 4 },
  { label: "Centro Oriente", value: 5 },
  { label: "Centro Sur", value: 6 },
  { label: "Golfo Centro", value: 7 },
  { label: "Golfo Norte", value: 8 },
  { label: "Jalisco", value: 9 },
  { label: "Noroeste", value: 10 },
  { label: "Norte", value: 11 },
  { label: "Oriente", value: 12 },
  { label: "Peninsular", value: 13 },
  { label: "Sureste", value: 14 },
  { label: "Valle de México Centro", value: 15 },
  { label: "Valle de México Norte", value: 16 },
  { label: "Valle de México Sur", value: 17 },
];

const columns = [
  { id: "category", label: "Categoría" },
  { id: "units", label: "Unidades" },
  { id: "transmition", label: "Transmisión" },
  { id: "distribution", label: "Distribución" },
  { id: "cenace", label: "CENACE" },
  { id: "basic", label: "Básico" },
  { id: "mem", label: "MEM" },
  { id: "energy", label: "Energía" },
  { id: "capacity", label: "Capacidad" },
];

const TariffProcessDetail = () => {
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [divtionsOptions, setDivitionsOptions] = useState([]);
  const [fetch, setFetch] = useState("");
  const { id } = useParams();

  const [currentId, setCurrentId] = useState(null);
  const [currentDivision, setCurrentDivision] = useState(null);
  const [localDetailsTariffProcess, setLocalDetailsTariffProcess] = useState([]);

  const { data: categories } = useGeneralList("movProcesoTarifasDetTbl", "idCategoria");
  const { data: detailsTariffProcess } = useDetailTariffProcess(id, fetch, divtionsOptions, categoriesOptions);
  const { refetch } = useDownloadTariffProcessingFile(currentId, currentDivision, 'image');
  const updateTariffProcess = useUpdateTariffProcessDetail();
  const updateAuditProcessedTariff = useUpdateAuditProcessedTariff();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmSave, setIsConfirmSave] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showConfirmModals, setShowConfirmModals] = useState({});

  const { data: isVerifiedData } = useGetAuditProcessedTariff(id);

  useEffect(() => {
    if (isVerifiedData !== undefined && isVerifiedData !== null) {
      setIsVerified(isVerifiedData.isVerified);
    }
  }, [isVerifiedData]);

  const handleFilterCategory = (value) => {
    setCategoriesOptions(value);
  };

  const handleFilterDivition = (value) => {
    setDivitionsOptions(value);
  };

  const handleGetDetails = () => {
    setFetch(new Date().getTime());
  };

  const handleDownloadClick = (id, division) => {
    setCurrentId(id);
    setCurrentDivision(division);
  };

  useEffect(() => {
    if (currentId && currentDivision) {
      refetch();
    }
  }, [currentId, currentDivision, refetch]);

  useEffect(() => {
    if (detailsTariffProcess) {
      setLocalDetailsTariffProcess(detailsTariffProcess);
    }
  }, [detailsTariffProcess]);

  const handleConfirmSave = async () => {
    setIsConfirmSave(true);
    setShowConfirmModals((prevModals) => ({
      ...prevModals,
      [currentDivision]: false,
    }));

  };

  const handleCancelSave = () => {
    setIsConfirmSave(false);
    setShowConfirmModals((prevModals) => ({
      ...prevModals,
      [currentDivision]: false,
    }));
  };

  const handleConfirmVerify = async () => {
    setShowVerifyModal(false);
    try {
      await updateAuditProcessedTariff.mutateAsync(id);
      setIsVerified(true);

    } catch (error) {
      console.error('Error updating tariff:', error);
    }
  };

  const handleCellValueChange = (modifiedDetails, id, idDivision) => {
    const objTariff = {
      tariffProcessId: id,
      divisionId: idDivision,
      details: modifiedDetails.map(detail => ({
        categoryId: detail.categoryId,
        unitId: detail.unitId,
        valueId: detail.valueId,
        value: detail.value
      }))
    };

    if (objTariff.details.length === 0) {
      return;
    }


    updateTariffProcess.mutate(objTariff, {
      onSuccess: () => {
        ShowMessage('Tarifa actualizada correctamente', 'success');
      },
      onError: (error) => {
        ShowMessage('Error al actualizar la tarifa', 'error');
      }
    });

  };

  const handleOpenConfirmModal = (divisionId) => {
    setCurrentDivision(divisionId);
    setShowConfirmModals((prevModals) => ({
      ...prevModals,
      [divisionId]: true,
    }));
  };

  const editableColumns = ['transmition', 'distribution', 'cenace', 'basic', 'mem', 'energy', 'capacity'];

  return (
    <>
      <CustomTemplate title="Detalle de tarifas" hasButtonBack >
        <ContentActions>
          <div>
            <p>División</p>
            <Select
              options={options}
              multiSelect
              onChange={handleFilterDivition}
              searchable={true}
            />
          </div>
          <div>
            <p>Categoría</p>
            <Select
              options={
                categories && Array.isArray(categories)
                  ? categories.map((category) => {
                    return {
                      label: category.description,
                      value: category.value,
                    };
                  })
                  : []
              }
              multiSelect
              onChange={handleFilterCategory}
              searchable={true}
            />
          </div>
          <CustomButton
            variant="outlined"
            onClick={handleGetDetails}
            style={{ alignSelf: "flex-end" }}
          >
            Consultar
          </CustomButton>
          <CustomButton
            variant="outlined"
            onClick={() => setShowVerifyModal(true)}
            style={{ alignSelf: "flex-end" }}
            disabled={isVerified}
          >
            Validar tarifas
          </CustomButton>
        </ContentActions>
        <ContentListTables>
          <div>
            {localDetailsTariffProcess && localDetailsTariffProcess.length > 0 && localDetailsTariffProcess.map((item, index) => {
              const models = item.tariffs?.map((itemDx, index) => ({
                id: index + 1,
                category: itemDx.category,
                units: itemDx.units,
                transmition: itemDx.transmition,
                distribution: itemDx.distribution,
                cenace: itemDx.cenace,
                basic: itemDx.basic,
                mem: itemDx.mem,
                energy: itemDx.energy,
                capacity: itemDx.capacity,
                // datos ids
                basicId: itemDx.basicId,
                capacityId: itemDx.capacityId,
                cenaceId: itemDx.cenaceId,
                distributionId: itemDx.distributionId,
                energyId: itemDx.energyId,
                memId: itemDx.memId,
                transmitionId: itemDx.transmitionId,
                unitId: itemDx.unitId,
                categoryId: itemDx.categoryId,
                idDivition: item.idDivition,
                isProcessed: itemDx.isProcessed,
              }));

              const isProcessedDivition = item.tariffs.some(tariff =>
                tariff.details?.some(detail => !detail.isProcessed)
              );

              return (
                <DataTableEditable
                  key={item.divition}
                  columns={columns}
                  data={models}
                  title={item.divition}
                  filterable
                  adjustColumn={["originalDocument", "actions"]}
                  onDownloadClick={() => handleDownloadClick(id, item.idDivition)}
                  pageSize={25}
                  onCellValueChange={(modifiedDetails) => handleCellValueChange(modifiedDetails, id, item.idDivition)}
                  editableColumns={editableColumns}
                  isConfirmSave={isConfirmSave}
                  setIsConfirmSave={setIsConfirmSave}
                  setLocalDetailsTariffProcess={setLocalDetailsTariffProcess}
                  idDivition={item.idDivition}
                  isProcessedDivition={isProcessedDivition}
                  onOpenConfirmModal={handleOpenConfirmModal}
                  divisionId={currentDivision}
                />
              );
            })}
          </div>
        </ContentListTables>
      </CustomTemplate>

      {showConfirmModals[currentDivision] && (
        <Dialog open={showConfirmModals[currentDivision]}>
          <StyledDelete container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
            <Grid item xs={12} marginBottom={3}><Warning /></Grid>
            <Grid item xs={12} marginBottom={2}>¿Estás seguro de que deseas guardar la modificación de los datos del detalle de tarifa? </Grid>
            <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={3} marginBottom={3}>
              <Grid item xs={4}>
                <Button variant="outlined" color="error" onClick={() => handleCancelSave(currentDivision)} fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={() => handleConfirmSave(currentDivision)} fullWidth>
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </StyledDelete>
          {isLoading && <Loader />}
        </Dialog>
      )}

      {showVerifyModal && (
        <Dialog open={showVerifyModal}>
          <StyledDelete container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
            <Grid item xs={12} marginBottom={3}><Warning /></Grid>
            <Grid item xs={12} marginBottom={2}>¿Desea confirmar los cambios realizados?</Grid>
            <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={3} marginBottom={3}>
              <Grid item xs={4}>
                <Button variant="outlined" color="error" onClick={() => setShowVerifyModal(false)} fullWidth>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button variant="contained" onClick={handleConfirmVerify} fullWidth>
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </StyledDelete>
          {isLoading && <Loader />}
        </Dialog>
      )}

    </>
  );
};

export default TariffProcessDetail;
