import { Grid } from "@mui/material";
import styled from "styled-components";

export const StyledTable = styled(Grid)`
    width: max-content;
      .head{
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => props.baseColor};
        border-radius: 5px;
        height: 55px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
      }

      .body{
        display: flex;
        align-items: center;
        background: ${props => props.baseColor}60;
        border-radius: 5px;
        width: 200px;
        height: 35px;
        margin-bottom: 5px;
        font-size: 12px;
      }

      .footer{
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${props => props.baseColor}60;
        border-radius: 5px;
        height: 35px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
      }
    .conceptcolumn{
        justify-content: start;
        font-weight: bold;
        padding-left: 10px;
    }
        
    .valuecolumn{
        justify-content: center;
        font-weight: bold;
    }

    .period{
        font-size: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
`;

export const StyledPricePay = styled(Grid)`
    margin-right: 1rem !important;
`;
