import styled from 'styled-components';
import background from './fondo.png';

export const Root = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
`;

export const LoginBox = styled.div`
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
  background: #ffffff;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 20px; /* Ajustado para centrar mejor el logo */

  & > svg {
    width: 400px;
    height: 100px;
  }
`;

export const StyledTextField = styled.div`
  width: 80%; /* Ocupa el 100% del contenedor */
  margin-bottom: 30px;
  & .MuiInputBase-root {
    background-color: #496fef;
    
  }

  & .MuiOutlinedInput-root {
    color: white;
    border-radius: 8px;
  }
`;


export const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%; /* Ocupa el 100% del contenedor */
  

  Button {
    background-color: white;
    color: #496fef;
    border: 2px solid #496fef;
    display: flex;
    align-items: center; /* Centrar icono y texto verticalmente */
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 35px;

    &:hover {
      background-color: #496fef;
      color: white;
    }

    svg {
      margin-right: 8px; /* Espacio entre el icono y el texto */
      width: 20px;
    }
  }
`;
