import { useEffect, useState } from "react";
import { CheckCircle, DoNotDisturbOn, Cancel,HourglassBottom,CloudDownload,Build} from "@mui/icons-material";
import { Grid } from "@mui/material";
import { StyledIconStatus } from "../styled.components";

export const IconStatus = ({ status }) => {
    const [color, setColor] = useState("");
    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState(null);

    useEffect(() => {
        console.log("status", status);
        switch (status) {
            case 1:
                setColor("#ffb800");
                setIcon(<HourglassBottom />);
                setLabel("En proceso");
                break;
            case 2:
                setColor("#ffb800");
                setIcon(<CloudDownload />);
                setLabel("Descargando documentos");
                break;
            case 3:
                setColor("#ffb800");
                setIcon(<Build />);
                setLabel("Procesando tarifas");
                break;

            case 4:
                setColor("#28c600");
                setIcon(<CheckCircle />);
                setLabel("Finalizado");
                break;

            case 5:
                setColor("#ffb800");
                setIcon(<DoNotDisturbOn />);
                setLabel("Finalizado parcial");
                break;

            case 6:
                setColor("#ff3636");
                setIcon(<Cancel />);
                setLabel("Con error");
                break;

            default:
                setColor("#ff3636");
                setIcon(<Cancel />);
                setLabel("Con error");
                break;
        }
    }, [status]);

    return (
        <Grid container columnSpacing={1}>
            <StyledIconStatus item iconcolor={color}>{icon}</StyledIconStatus>
            <Grid item>{label}</Grid>
        </Grid>
    )
}