import { useEffect, useState } from "react";
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate";
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import DataTable from "../../components/DataTable/DataTable";
import CustomButton from "../../components/CustomButtonStyled/CustomButton";
import styled from "styled-components";
import { IconContainer, StyledConfirm, StyledDialog, StyledNameContent, StyledTextField } from "./styled.components";
import { CustomModal } from "../../components/Modal/CustomModal";
import { useDeleteUser, useGetListProfile, useGetListUsers, useSaveNewUser, useSaveUpdateUser, useUpdateChangePassword } from "./api/UserManagementApi";
import { min, pad, set, update } from "lodash";
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal";
import { EditNote, Groups, Password, Person, PersonOff, Visibility, VisibilityOff } from "@mui/icons-material";
import { Loader } from "../../components/Base/Loader";
import { ShowMessage } from "../../utils/utils";
import Select from "../../components/Select/Select";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    "& img": {
        borderRadius: "3px",
    },
}));

const columns = [
    { id: "id", label: "ID" },
    { id: "name", label: "Nombre" },
    { id: "user", label: "Usuario" },
    { id: "status", label: "Estatus" },
    { id: "actions", label: "Acciones" },
];

export const UserManagementContainer = () => {

    const [modelTable, setModelTable] = useState([]);
    const [showUserModal, setShowUserModal] = useState(false);
    const [modalMode, setModalMode] = useState('add'); // add or edit

    const [userName, setUserName] = useState('');
    const [lastName, setLastName] = useState('');
    const [motherLastName, setMotherLastName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userId, setUserId] = useState(null);
    const [selectedProfileId, setSelectedProfileId] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [errorFields, setErrorFields] = useState({});

    const [isUpdated, setIsUpdated] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [message, setMessage] = useState("");

    const { data: listUsers } = useGetListUsers(isUpdated);
    const newUser = useSaveNewUser();
    const updateUser = useSaveUpdateUser();
    const deleteUser = useDeleteUser();
    const { data: listProfiles } = useGetListProfile();
    const updatePassword = useUpdateChangePassword();

    const [modalMessage, setModalMessage] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    useEffect(() => {
        if (listUsers && Array.isArray(listUsers)) {
            const models = listUsers.map((user) => ({
                id: user.userId,
                name: user.fullName,
                user: user.userKey,
                status: user.isActive ? 'Activo' : 'Inactivo',
                actions: (
                    <Grid container justifyContent="center" style={{ width: 'auto' }}>
                        <Grid item>
                            <CustomIconButton
                                color="primary"
                                size="medium"
                                onClick={() => handleEditUser(user)}
                                disabled={!user.isActive}
                                style={{ cursor: user.isActive ? "pointer" : "not-allowed" }}
                            >
                                <IconContainer style={{ background: user.isActive ? "#f39c27" : "#535767" }}>
                                    <EditNote style={{ color: "#fff" }} />
                                </IconContainer>
                            </CustomIconButton>

                            <CustomIconButton
                                color="primary"
                                size="medium"
                                onClick={() => handleToggleUserStatus(user)}
                            >
                                <IconContainer style={{ background: user.isActive ? "#1ec71a" : "#d64242" }}>
                                    {user.isActive ? (
                                        <Person style={{ color: "#fff" }} />
                                    ) : (
                                        <PersonOff style={{ color: "#fff" }} />
                                    )}
                                </IconContainer>
                            </CustomIconButton>

                            <CustomIconButton
                                color="primary"
                                size="medium"
                                onClick={() => handleOpenPasswordUpdateModal(user)}
                                disabled={!user.isActive}
                                style={{ cursor: user.isActive ? "pointer" : "not-allowed" }}
                            >
                                <IconContainer style={{ background: user.isActive ? "#f39c27" : "#535767" }}>
                                    <Password style={{ color: "#fff" }} />
                                </IconContainer>
                            </CustomIconButton>

                        </Grid>
                    </Grid>

                ),
            }));
            setModelTable(models);
        }
    }, [listUsers]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateFields = () => {
        let valid = true;
        let errors = {};

        if (modalMode === 'add' || modalMode === 'edit') {
            // Validaciones para crear un nuevo usuario
            if (!userName.trim()) {
                valid = false;
                errors.userName = "El nombre es requerido.";
            }
            if (!lastName.trim()) {
                valid = false;
                errors.lastName = "El apellido paterno es requerido.";
            }
            if (!userEmail.trim()) {
                valid = false;
                errors.userEmail = "El correo electrónico es requerido.";
            } else if (!validateEmail(userEmail.trim())) {
                valid = false;
                errors.userEmail = "El correo electrónico no es válido.";
            }

            if (!selectedProfileId) {
                valid = false;
                errors.profile = "El perfil es requerido.";
            }
        }

        if (modalMode === 'add' || modalMode === 'updatePassword') {
            // Validaciones para crear un nuevo usuario
            if (!password.trim()) {
                valid = false;
                errors.password = "La contraseña es requerida.";
            }
            if (password.trim() !== confirmPassword.trim()) {
                valid = false;
                errors.confirmPassword = "Las contraseñas no coinciden.";
            }

            if (password.length < 6) {
                valid = false;
                errors.password = 'La contraseña debe tener al menos 6 caracteres.';
            }
        }

        setErrorFields(errors);
        return valid;
    };

    const handleAddUser = () => {
        setErrorFields({});
        setShowUserModal(true);
        setModalMode('add');
        setUserName("");
        setLastName("");
        setMotherLastName("");
        setUserEmail("");
        setPassword("");
        setConfirmPassword("");
        setSelectedProfileId(null);
    };

    const handleEditUser = (user) => {
        setErrorFields({});
        setModalMode('edit');
        setShowUserModal(true);
        setUserName(user.firstName);
        setLastName(user.lastName);
        setMotherLastName(user.middleName);
        setUserEmail(user.email);
        setUserId(user.userId);
        setPassword("");
        setConfirmPassword("");
        setSelectedProfileId(user.profileId);
    };

    const handleOpenPasswordUpdateModal = (user) => {
        setModalMode('updatePassword');
        setShowUserModal(true);
        setUserId(user.userId);
        setPassword("");
        setConfirmPassword("");
    };

    const handlePasswordUpdate = () => {
        if (validateFields()) {
            setIsLoading(true);
            updatePassword.mutate({
                userId: userId,
                password: password.trim(),
            }, {
                onSuccess: () => {
                    setShowUserModal(false);
                    setIsLoading(false);
                    setMessage("Se actualizo con éxito la contraseña del usuario.");
                    setShowConfirmationModal(true);
                    setIsUpdated("updated" + new Date().getTime());
                },
                onError: (error) => {
                    setIsLoading(false);
                }
            });
        }
    };

    const handleSaveUser = () => {
        if (validateFields()) {
            setIsLoading(true);
    
            newUser.mutate({
                firstName: userName.trim(),
                lastName: lastName.trim(),
                middleName: motherLastName.trim(),
                email: userEmail.trim(),
                password: password.trim(),
                profileId: selectedProfileId
            }, {
                onSuccess: () => {
                    setShowUserModal(false);
                    setIsLoading(false);
                    setShowConfirmationModal(true);
                    setMessage("Se dió de alta con éxito el usuario.");
                    setIsUpdated("updated" + new Date().getTime());
                },
                onError: (error) => {
                    setIsLoading(false);
                }
            });
        }
    };
    

    const handleSaveEditUser = () => {
        if (validateFields()) {
            setIsLoading(true);
            // Utilizar la función updateUser para guardar los cambios
            updateUser.mutate({
                userId: userId, // El ID del usuario que se está editando
                firstName: userName.trim(),
                lastName: lastName.trim(),
                middleName: motherLastName.trim(),
                email: userEmail.trim(),
                password: password.trim(), // Incluir solo si se ha cambiado la contraseña,
                profileId: selectedProfileId
            }, {
                onSuccess: () => {
                    setShowUserModal(false);
                    setIsLoading(false);
                    setShowConfirmationModal(true);
                    setMessage("Se editó con éxito la información del usuario.");
                    setIsUpdated("updated" + new Date().getTime());
                },
                onError: (error) => {
                    setIsLoading(false);
                }
            });
        }
    };

    const handleToggleUserStatus = async (user) => {
        setCurrentUser(user);
        setModalMessage(user.isActive
            ? `¿Desea desactivar al usuario "${user.firstName} ${user.lastName}?"`
            : `¿Desea activar al usuario "${user.firstName} ${user.lastName}?"`);
        setShowModal(true);
    };

    const handleConfirmAction = async () => {
        if (currentUser) {
            try {
                const updatedStatus = !currentUser.isActive;

                //para eliminar o actualizar el estado del usuario
                deleteUser.mutate(currentUser.userId, {
                    onSuccess: () => {
                        // Muestra un mensaje basado en el nuevo estado del usuario
                        ShowMessage(updatedStatus ? 'Usuario activado con éxito' : 'Usuario desactivado con éxito', 'success');

                        // Actualiza la lista de usuarios localmente
                        setIsUpdated("updated" + new Date().getTime());
                    },
                    onError: (error) => {
                        ShowMessage('No se pudo actualizar el estado del usuario', 'error');
                    }
                });
            } catch (error) {
                ShowMessage('Ocurrió un error al intentar actualizar el estado del usuario', 'error');
            } finally {
                setShowModal(false);
                setCurrentUser(null);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Función para alternar la visibilidad de la confirmación de contraseña
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Comparar contraseñas en tiempo real
    useEffect(() => {
        if (password && confirmPassword) {
            if (password !== confirmPassword) {
                setErrorFields(prev => ({ ...prev, confirmPassword: 'Las contraseñas no coinciden' }));
            } else {
                setErrorFields(prev => ({ ...prev, confirmPassword: '' }));
            }
        }
    }, [password, confirmPassword]);


    const handleProfileChange = (profileId) => {
        setSelectedProfileId(profileId);
    };

    const validateText = (text) => {
        // Expresión regular para permitir solo letras y espacios
        const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/;
        return regex.test(text);
    };

    const removeSpaces = (value) => value.replace(/\s/g, '');

    return (
        <>
            <CustomTemplate title="Administración de usuarios">
                <Grid container>
                    <Grid item xs={12}>
                        <DataTable
                            columns={columns}
                            data={modelTable}
                            adjustColumn={["actions"]}
                            filterable
                            customBtn={
                                <Grid item xs={6} container justifyContent={"right"}>
                                    <Grid item>
                                        <CustomButton
                                            variant="outlined"
                                            onClick={handleAddUser}
                                        >
                                            Nuevo Usuario
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Grid>
                </Grid>
            </CustomTemplate >

            {showConfirmationModal &&
                <ConfirmationModal
                    open={true}
                    onSucces={() => setShowConfirmationModal(false)}
                    isAvailableAcept={true}
                    message={message}
                />
            }

            {showUserModal && (
                <CustomModal
                    title={
                        modalMode === 'add' ? "Nuevo usuario" :
                            modalMode === 'edit' ? "Editar Usuario" :
                                "Actualización de contraseña"
                    }
                    open={showUserModal}
                    isLoading={isLoading}
                    labelAccept={modalMode === 'updatePassword' ? "Actualizar" : "Guardar"}
                    close={() => setShowUserModal(false)}
                    onSuccess={
                        modalMode === 'add' ? handleSaveUser :
                            modalMode === 'edit' ? handleSaveEditUser :
                                handlePasswordUpdate
                    }
                    width="100%"
                    maxWidth="md"
                >
                    <StyledNameContent container>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {(modalMode === 'add' || modalMode === 'edit') && (
                                    <>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Nombres</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    value={userName}
                                                    //onChange={(e) => setUserName(e.target.value)}
                                                    onChange={(e) => {
                                                        const valueName = e.target.value.slice(0, 50);
                                                        if (validateText(valueName)) {
                                                            setUserName(valueName);
                                                        }
                                                    }}
                                                    error={!!errorFields.userName}
                                                />
                                                {errorFields.userName && (
                                                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                        {errorFields.userName}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Apellido paterno</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    value={lastName}
                                                    //onChange={(e) => setLastName(e.target.value)}
                                                    onChange={(e) => {
                                                        const valueAP = e.target.value.slice(0, 50);
                                                        if (validateText(valueAP)) {
                                                            setLastName(valueAP);
                                                        }
                                                    }}
                                                    error={!!errorFields.lastName}
                                                />
                                                {errorFields.lastName && (
                                                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                        {errorFields.lastName}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Apellido materno</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    value={motherLastName}
                                                    //onChange={(e) => setMotherLastName(e.target.value)}
                                                    onChange={(e) => {
                                                        const valueAM = e.target.value.slice(0, 50);
                                                        if (validateText(valueAM)) {
                                                            setMotherLastName(valueAM);
                                                        }
                                                    }}
                                                    error={!!errorFields.motherLastName}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Correo electrónico</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    value={userEmail}
                                                    onChange={(e) => setUserEmail(e.target.value.slice(0, 100))}
                                                    error={!!errorFields.userEmail}
                                                />
                                                {errorFields.userEmail && (
                                                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                        {errorFields.userEmail}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                                {(modalMode === 'add' || modalMode === 'updatePassword') && (
                                    <>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Contraseña</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={(e) => {
                                                        const newPassword = removeSpaces(e.target.value).slice(0, 20);
                                                        setPassword(newPassword);
                                                        // Validación de la longitud mínima
                                                        if (newPassword.length < 6) {
                                                            setErrorFields(prev => ({ ...prev, password: 'La contraseña debe tener al menos 6 caracteres.' }));
                                                        } else {
                                                            setErrorFields(prev => ({ ...prev, password: '' }));
                                                        }
                                                    }}
                                                    error={!!errorFields.password}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={togglePasswordVisibility}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errorFields.password && (
                                                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                        {errorFields.password}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={{ paddingTop: '1px' }}>
                                            <Box mt={2}>
                                                <Typography variant="subtitle2" gutterBottom>Confirmar Contraseña</Typography>
                                                <StyledTextField
                                                    fullWidth
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(removeSpaces(e.target.value).slice(0, 20))}
                                                    error={!!errorFields.confirmPassword}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle confirm password visibility"
                                                                    onClick={toggleConfirmPasswordVisibility}
                                                                >
                                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                                {errorFields.confirmPassword && (
                                                    <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                        {errorFields.confirmPassword}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Grid>
                                    </>
                                )}

                                {(modalMode === 'add' || modalMode === 'edit') && (
                                    <Grid item xs={12} sm={6} style={{ paddingTop: '1px'}}>
                                        <Box mt={1}>
                                            <Typography variant="subtitle2" gutterBottom>Perfil</Typography>
                                            <Select 
                                                style={{ minWidth: "0px", backgroundColor: "#fff", padding: "12px 12px" }}
                                                defaultValue={selectedProfileId}
                                                options={
                                                    listProfiles && Array.isArray(listProfiles)
                                                        ? listProfiles.map((profile) => ({
                                                            label: profile.description,
                                                            value: profile.profileId,
                                                        }))
                                                        : []
                                                }
                                                onChange={handleProfileChange}
                                                searchable={true}
                                            />
                                            {errorFields.profile && (
                                                <Typography variant="caption" color="error" style={{ marginTop: '4px' }}>
                                                    {errorFields.profile}
                                                </Typography>
                                            )}
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </StyledNameContent>
                </CustomModal>
            )}

            {showModal &&
                <StyledDialog open={showModal} onClose={() => setShowModal(false)}>
                    <StyledConfirm container justifyContent={"center"} alignItems={"center"} textAlign={"center"}>
                        <Grid item xs={12} marginBottom={2}><Groups style={{ fontSize: "90px", color: "#496fef" }} /></Grid>
                        <Grid item xs={12} marginTop={4}>{modalMessage}</Grid>
                        <Grid item xs={12} container columnSpacing={2} textAlign={"center"} justifyContent={"center"} alignItems={"center"} marginTop={10} marginBottom={2}>
                            <Grid item xs={4}>
                                <Button variant="outlined" color="error" fullWidth onClick={() => setShowModal(false)}>
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid item xs={4}>
                                <CustomButton
                                    fullWidth
                                    variant="contained"
                                    onClick={handleConfirmAction}
                                    disabled={isLoading}
                                >
                                    <Loader size={'30px'} loading={false} /> Aceptar
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </StyledConfirm>
                </StyledDialog>}

        </>
    );
};