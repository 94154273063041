import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import useMenu from '../../hooks/useMenu';
import { useIsFetching } from 'react-query';
import { Loader } from '../Base/Loader';
import StoreUser from '../../storeUser';
import Menu from '../Menu';
import { useChangeUser } from '../../hooks/useChangeUser';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Router from '../../router/Router';
import { Container } from './LayoutContainerStyle';
import DashboardContainer from '../../views/Dashboard/DashboardContainer';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#19857b',
      backgroundColor: '#d3d6dd',
    },
  },
});

const LayoutContainer = ({ team }) => {
  const { userData } = useChangeUser();
  const isFetching = useIsFetching();
  const routes = useMenu();
  const storeUser = new StoreUser();

  useEffect(() => {
    if (routes.statusFetch === "not_authorized") {
      storeUser.deleteUserData();
      window.location.href = "/auth/signin";
    }
  }, [routes.statusFetch]);

  const safeRoutes = routes && routes.routes ? routes.routes : [];
  const isLoading = routes && routes.loading ? routes.loading : false;

  return (
    <ThemeProvider theme={theme}>
      <Backdrop style={{ zIndex: 10000, color: 'white', textAlign: "center" }} open={isFetching > 0}>
        <Loader size={'30px'} />
      </Backdrop>
      <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: "#d3d6dd" }}>
        <BrowserRouter>
          <div
            style={{ width: '4%' }}
          > 
            <Menu 
              routes={safeRoutes}
              loading={isLoading}
            />
          </div>
          <Routes>
            <Route path="/" element={<DashboardContainer />} />
            <Route path="/energy/home" element={<DashboardContainer />} />
            <Route path="/energy/*" element={
                <Container style={{ width: '92%'}}>
                  <Router />
                </Container>
            }/>
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default LayoutContainer;