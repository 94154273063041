import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';

const getProjections = () => {
  return axiosServiceV1.get(`Projection/projection-movements`);
};

export const useGetProjections = () => {
  return useQuery({
    queryKey: ['projection'],
    queryFn: () => getProjections(),
    enabled: true
  });
};

const getProjectionsById = (id) => {
  return axiosServiceV1.get(`Projection/projection-movements/${id}`);
};

export const useGetProjectionsById = (id) => {
  return useQuery({
    queryKey: ['projectionbyid'],
    queryFn: () => getProjectionsById(id),
    enabled: false
  });
};

const getDownloadTime = () => {
  return axiosServiceV1.get(`General/movProyeccionesTbl/tiempoDescarga`);
};

export const useGetDownloadTime = () => {
  return useQuery({
    queryKey: ['downloadTime'],
    queryFn: () => getDownloadTime(),
    enabled: true
  });
};

const getInvestmentProjection = (data) => {
  return axiosServiceV1.get(`InvestmentProjection/investement-projection/${data.megaWatts}/${data.timeDownload}`);
};

export const useGetInvestmentProjection = (data) => {
  return useQuery({
    queryKey: ['investmentProjection'],
    queryFn: () => getInvestmentProjection(data),
    enabled: false
  });
};

const getMonthlyProjectionForNode = (nodeId, month, year, mw, hour) => {
  return axiosServiceV1.get(`InvestmentProjection/calculate-node/${nodeId}/${month}/${year}/${mw}/${hour}`);
};

export const useMonthlyProjectionForNode = (nodeId, month, year, mw, hour) => {
  return useQuery({
      queryKey: ['monthlyProjection', nodeId, month, year, mw, hour],
      queryFn: () => getMonthlyProjectionForNode(nodeId, month, year, mw, hour),
      enabled: false
  });
};

const getAnnualProjectionForNode = (nodeId, year, mw, hour) => {
  return axiosServiceV1.get(`InvestmentProjection/calculate-node-year/${nodeId}/${year}/${mw}/${hour}`);
};

export const useAnnualProjectionForNode = (nodeId, year, mw, hour) => {
  return useQuery({
      queryKey: ['annualProjection', nodeId, year, mw, hour],
      queryFn: () => getAnnualProjectionForNode(nodeId, year, mw, hour),
      enabled: false
  });
};

const getLocalMarginalPriceMDA = (nodeId, date) => {
  return axiosServiceV1.get(`InvestmentProjection/node-projection/${nodeId}/${date}`);
}

export const useGetLocalMarginalPriceMDA = (nodeId, date) => {
  return useQuery({
      queryKey: ['localMarginalPriceMDA'],
      queryFn: () => getLocalMarginalPriceMDA(nodeId, date),
      enabled: !!nodeId && !!date
  });
}

const createProjections = (data) => {
  return axiosServiceV1.post(`projection/projection-movements`, data);
}

export const useCreateProjections = () => {
  return useMutation({
    onError: (data, __, ___) => {
    },
    onSuccess: async (data, dataDto, _) => {
      if (!data) {
        //ShowMessage('Se creo correctamente la proyección', 'success');
      }

    },

    mutationFn: (data) => createProjections(data).then(res => res).catch(err => null),
  });
};

const deleteProjection = (id) => {
  return axiosServiceV1.delete(`projection/${id}`);
}

export const useDeleteProjection = () => {
  return useMutation({
    onError: (data, __, ___) => {
    },
    onSuccess: async (data, dataDto, _) => {
      //ShowMessage('Se elimino la proyección', 'success');
    },

    mutationFn: (id) => deleteProjection(id).then(res => res).catch(err => null),
  });
};
