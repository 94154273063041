import axiosServiceV1 from '../../../apiService/axiosService';
import { useMutation, useQuery } from 'react-query';
import { ShowMessage } from '../../../utils/utils';


const getGeneralListByColumnFetch = async (table, column) => {
  return axiosServiceV1.get(`General/${table}/${column}`);
}

const getDetailTariffProcessFetch = async (id, divitions = null, categories = null) => {
  if(categories !== null) {
    categories = categories.map(category => "idCategory="+category).join('&');
  }

  if(divitions !== null) {
    divitions = divitions.map(divition => "idDivition="+divition).join('&');
  }

  const queries = [divitions, categories].filter(query => query !== null).join('&');

  const url = `TariffProcessing/processed-tariff/detail/${id}${queries ? '?'+queries : ''}`
  return axiosServiceV1.get(url);
}

export const useGeneralList  = (table, column) => {
  return useQuery({
    queryKey: 'getTariffProcess',
    queryFn: async () => await getGeneralListByColumnFetch(table, column),
  });
}

export const useDetailTariffProcess  = (id, fetch, divitions, categories) => {
  return useQuery({
    queryKey: ['detailtProcess', fetch],
    queryFn: async () => await getDetailTariffProcessFetch(id, divitions, categories),
    enabled: true,
  });
}


export const getTariffProcessingFile = (idTariffProcess, idDivision) => {
  const url = `TariffProcessing/processed-tariff/image/${idTariffProcess}/${idDivision}`;
  const responseType = 'arraybuffer';
  return axiosServiceV1.get(url, { responseType });
};

export const useDownloadTariffProcessingFile = (idTariffProcess, idDivision, fileType) => {
  return useQuery({
    queryKey: ['tariff-processing-file', idTariffProcess, idDivision, fileType],
    queryFn: () => getTariffProcessingFile(idTariffProcess, idDivision, fileType)
      .then(res => {
        const mimeType = fileType === 'image' ? 'image/png' : 'application/pdf';
        const fileExtension = fileType === 'image' ? 'png' : 'pdf';
        downloadFile(res, `Tarifa_procesado_${idTariffProcess}_${idDivision}`, mimeType, fileExtension);
      })
      .catch(err => {
        console.error("Error downloading file:", err);
        ShowMessage('Ocurrió un error al intentar descargar el archivo', 'error');
      }),
    enabled: false 
  });
};

const downloadFile = (data, name, mimeType, fileExtension) => {
  if (!data) {
    ShowMessage('Ocurrió un error al intentar descargar el archivo', 'error');
    return;
  }
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${name}.${fileExtension}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export const updateTariffProcessDetail = (data) => {
  return axiosServiceV1.put(`tariffProcessing/update-processed-tariff-detail`, data);
}

export const useUpdateTariffProcessDetail = (data) => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar actualizar la tarifa', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              ShowMessage('Se actualizo correctamente', 'success');
          }

      },

      mutationFn: (data) => updateTariffProcessDetail(data).then(res => res).catch(err => null),
  });
};

export const updateAuditProcessedTariff = (id) => {
  return axiosServiceV1.put(`tariffProcessing/update-audit-processed-tariff/${id}`);
}

export const useUpdateAuditProcessedTariff = () => {
  return useMutation({
      onError: (_, __, ___) => {
          ShowMessage('Ocurrió un error al intentar actualizar la tarifa', 'error');
      },
      onSuccess: (data, dataDto, _) => {
          if (data) {
              ShowMessage('Se actualizó correctamente', 'success');
          }
      },
      mutationFn: (id) => updateAuditProcessedTariff(id).then(res => res).catch(err => null),
  });
};

export const getAuditProcessedTariff = (id) => {
  return axiosServiceV1.get(`tariffProcessing/processed-tariff-audit/${id}`);
};

export const useGetAuditProcessedTariff = (id) => {
  return useQuery({
      queryKey: ['auditProcessedTariff', id],
      queryFn: () => getAuditProcessedTariff(id),
      enabled: true
  });
};