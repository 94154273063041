import { Grid } from "@mui/material"
import CustomTemplate from "../../components/CustomTemplate/CustomTemplate"
import { ContentSection } from "../../components/Base/TitleSection/TitleSection.Style"
import { TitleSection } from "../../components/Base/TitleSection"
import { InputText } from "../../components/Base/Inputs"
import { useForm } from "../../hooks/useForm"
import { IntegerAndDecimalValidation } from "../../utils/utils"
import { PrimaryButton } from "../../components/Base/Buttons/Buttons"
import { useApplyConfiguration, useExchangeRateBanxico, useGetExchangeRate } from "./api/ExchangeRateApi"
import { useEffect, useState } from "react"
import { ConfirmationModal } from "../../components/Modal/ConfirmationModal"

export const ExchangeRate = () => {
    const date = new Date();
    const startDate = date.getFullYear() + "-" + String(date.getMonth() + 1).padStart(2, '0') + "-" + String(date.getDate()).padStart(2, '0');

    const exchangeRateQuery = useGetExchangeRate();
    const exchangeRateBanxicoQuery = useExchangeRateBanxico({ startDate: startDate, endDate: startDate });
    const applyConfiguration = useApplyConfiguration();
    const [showConfimationConfig, setShowConfirmationConfig] = useState(false);
    const [message, setMessage] = useState("");
    const [showConfimationExchange, setShowConfirmationExchange] = useState(false);

    const { setPropertiesValue, handleSubmit, handleChange, data: rateData, errors } = useForm({
        initialValues: null,
        validations: {
            exchangeRate: {
                required: { message: "El tipo de cambio es requerido" },
            },
        },
        onSubmit: async () => {
            const result = await applyConfiguration.mutateAsync(rateData);
            if (result) {
                setMessage("Se aplico los cambios con éxito");
                setShowConfirmationConfig(true);
            } else {
                setShowConfirmationConfig(false);
            }
        }
    });

    const handleRate = (value) => {
        if (IntegerAndDecimalValidation(value, 10, 4).isValid) {
            setPropertiesValue({ exchangeRate: value });
        } else if (value === "") {
            setPropertiesValue({ exchangeRate: "" });
        }
    }

    useEffect(() => {
        if (exchangeRateQuery.isSuccess && exchangeRateQuery.data?.exchangeRate) {
            setPropertiesValue({ exchangeRate: exchangeRateQuery.data?.exchangeRate });
        } if (exchangeRateQuery.isSuccess && !exchangeRateQuery.data?.exchangeRate) {
            exchangeRateBanxicoQuery.refetch();
        }
    }, [exchangeRateQuery.isSuccess]);

    useEffect(() => {
        if (exchangeRateBanxicoQuery.isSuccess && exchangeRateBanxicoQuery.data) {
            setShowConfirmationExchange(true);
            setPropertiesValue({ exchangeRate: exchangeRateBanxicoQuery.data });
        }
    }, [exchangeRateBanxicoQuery.isSuccess])

    return (
        <CustomTemplate title="Panel de configuración de proyección de inversión" hasButtonBack>
            <ContentSection container>
                <Grid container columnSpacing={3}>
                    <TitleSection title={"Tipo de cambio"} />
                    <Grid item>
                        <InputText
                            fullWidth
                            type={"number"}
                            title="Tipo de cambio (pesos)"
                            value={rateData?.exchangeRate}
                            onChange={(e) => handleRate(e.target.value)}
                            error={errors && errors.exchangeRate}
                            helperText={errors.exchangeRate || ""}
                        />
                    </Grid>
                    <Grid container justifyContent={"center"} alignItems={"center"} textAlign={"center"} marginTop={5}>
                        <Grid item xs={4}>
                            <PrimaryButton
                                handleClick={async () => await handleSubmit()}
                                text={"Aplicar"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSection>
            <ConfirmationModal
                open={showConfimationConfig}
                onSucces={() => setShowConfirmationConfig(false)}
                isAvailableCancel={false}
                isAvailableAcept={true}
                message={message}
                status={"success"}
            />

            <ConfirmationModal
                open={showConfimationExchange}
                onSucces={() => setShowConfirmationExchange(false)}
                isAvailableCancel={false}
                isAvailableAcept={true}
                message={`No pudimos encontrar un tipo de cambio. En su lugar, se utilizará el tipo de cambio oficial de Banxico.`}
                status={"warning"}
            />
        </CustomTemplate>
    )
}