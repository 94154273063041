import { BehaviorSubject } from 'rxjs';

export default class StoreUser {
    constructor() {
        this._key = "energy-root";
        this._storeUser = new BehaviorSubject(this.getFromLocalStorage());
        this.$loading = new BehaviorSubject(false);
        this.$theme = new BehaviorSubject(this.initValueTheme);
    }

    initValueTheme = {
        fontFamily: "Open-Sans, Helvetica, Sans-Serif",
        primaryBackgroundColor: "#FFFFFF",
        secondBackgroundColor: "#e0e0e0",
        primaryColor: "#00B3B0",
        secondColor: "#DA64FF",
        primaryFontColor: "black",
        icon: "",
        margintoptable: "0px",
        linetable:"#00B3B0",
        bordericon: "10%",
        iconSize: "2rem",
        tableOverFlow: "auto",
        marginswithLeft: "0px",
        visibility: "hidden",
        fontSize: "inherit",
    }

    isAuthenticated() {
        const userData = this.getFromLocalStorage();
        return userData !== null;
    }


    get getLoading() {
        return this.$loading.asObservable();
    }

    setLoading(flag) {
        this.$loading.next(flag);
    }

    get infoUser() {
        return this._storeUser.asObservable();
    }

    addUserData(userData) {
        this._storeUser.next({ ...userData });
        this.saveInLocalStorage();
    }

    deleteUserData() {
        this._storeUser.next(null);
        localStorage.removeItem(this._key);
    }

    addUserInfo(userInfo) {
        const localData = localStorage.getItem(this._key);
        const userData = JSON.parse(localData);
        const data = userData.userData;
        data.idUsuario = userInfo.userId;
        this._storeUser.next({ ...userData, userData: data });
        this.saveInLocalStorage();
    }

    addMenu(menu) {
        const localData = localStorage.getItem(this._key);
        const userData = JSON.parse(localData);
        this._storeUser.next({ ...userData, menu: menu });
        this.saveInLocalStorage();
    }

    /*addUserProfile(userProfile) {
        const localData = localStorage.getItem(this._key);
        const userData = JSON.parse(localData);
        this._storeUser.next({ ...userData, profile: userProfile });
        this.saveInLocalStorage();
    }*/

    addModule(module) {
        const localData = localStorage.getItem(this._key);
        const userData = JSON.parse(localData);
        this._storeUser.next({ ...userData, module: module });
        this.saveInLocalStorage();
    }

    extractUserInfo() {
        return this._storeUser.getValue();
    }

    saveInLocalStorage() {
        localStorage.setItem(this._key, JSON.stringify(this.extractUserInfo()));
    }

    getFromLocalStorage() {
        const localData = localStorage.getItem(this._key);
        if (localData) {
            const userData = JSON.parse(localData);
            return userData;
        }
        return null;
    }

    get getTheme() {
        return this.$theme.asObservable();
    }

    setTheme(element) {
        this.$theme.next({
            ...element
        })
    }

}
