import { Accordion, Grid } from "@mui/material";
import styled from "styled-components";

export const StyledDetail = styled(Accordion)`
    box-shadow: none !important;

    .MuiAccordionSummary-root{
        padding: 0;
        border-bottom: 2px solid #a3b6f7;

        .IconDelete{
            font-size: 25px;
            color: #CB3234;
        }
    }

    .MuiSvgIcon-fontSizeMedium{
        font-size: 3rem;
        color: #ff922d;
    }
`;

export const StyledDelete = styled(Grid)`
    padding: 10px;

    .MuiSvgIcon-root{
        font-size: 45px;
        color: #ff922d;
    }
`;