import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, Icon, Typography } from "@mui/material"
import { InputTextReadOnly } from "../../../../../components/Base/Inputs"
import { Editor } from "../../../../../components/Editor"
import Select from "../../../../../components/Select/Select"
import { IconArrow } from "../../../../../components/Icon"
import { StyledBattery, StyledIcon } from "../../Projections.Styled"
import { useContext, useEffect, useState } from "react"
import { ProjectionsContext } from "../../../../../utils/context/projectionsContext"
import { useGetBatteriesById, useGetBatteriesWithWarranties } from "../../../../BatteryCatalog/api/BatteryApi"
import { NumberFormat } from "../../../../../utils/utils"

export const DetailBattery = () => {
    const { state, selectBatterie, disabled, setSelectBatterie, handleValues } = useContext(ProjectionsContext);
    const { data: batteriesData, isSuccess: isBatteriesSuccess } = useGetBatteriesWithWarranties();
    const batterieById = useGetBatteriesById(selectBatterie?.batteryId);
    const [optionsBatteriesWarranty, setOptionsBatteriesWarranty] = useState([]);
    const [expanded, setExpanded] = useState('');
    const [descriptionBattery, setDescriptionBattery] = useState(null);
    const [descriptionWarranty, setDescriptionWarranty] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const getDefaultValue = () => {
        if (selectBatterie && optionsBatteriesWarranty.length > 0 && disabled) return optionsBatteriesWarranty.find(x => x.value.batteryId === selectBatterie?.batteryId && x.value.warrantyId === selectBatterie.warrantyId).value;
        return null
    }

    useEffect(() => {
        if (isBatteriesSuccess && batteriesData) {
          const auxData = [];
          batteriesData.forEach((value) => {
            auxData.push({
              value: value,
              label: `${value.brand} - ${value.model} - ${value.warranty}`,
            });
          });
          setOptionsBatteriesWarranty(auxData);
        }
      }, [isBatteriesSuccess, batteriesData]);

    useEffect(() => {
        if (selectBatterie) {
            batterieById.refetch();
        } else {
            setDescriptionBattery(null);
            setExpanded("");
        }
    }, [selectBatterie]);

    useEffect(() => {
        if (batterieById.isSuccess && batterieById.data && selectBatterie?.warrantyId) {
            const auxCostKW = batterieById.data.listWarranty.find(x => x.id === selectBatterie?.warrantyId);
            handleValues({ ...state, costKw: auxCostKW?.costKw, batteryId: selectBatterie?.batteryId, warrantyId: selectBatterie?.warrantyId, deduction: 0 });
            
            const descriptionBattery = batterieById.data?.characteristicsBattery
                ? JSON.parse(batterieById.data.characteristicsBattery)
                : "";

            const descriptionWarranty = auxCostKW?.characteristics
                ? JSON.parse(auxCostKW.characteristics)
                : "";

            setDescriptionBattery(descriptionBattery?.content || []);
            setDescriptionWarranty(descriptionWarranty?.content || []);

            setExpanded("panel1");
        }
    }, [batterieById.data, selectBatterie?.warrantyId]);

    return (
        <StyledBattery container columnSpacing={3} marginTop={3}>
            <Grid item container columnSpacing={3}>
                <Grid item xs={4} mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Batería</Typography>
                    <Select
                        options={optionsBatteriesWarranty}
                        onChange={(value) => {
                            batterieById.remove();
                            setDescriptionBattery(null);
                            setSelectBatterie(value);
                            handleValues({ ...state, deduction: 0 })
                        }}
                        searchable={true}
                        isLoading={disabled ? !(disabled && selectBatterie?.batteryId) : false}
                        defaultValue={getDefaultValue()}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={4}>
                    <InputTextReadOnly
                        className={"textOnlyBattery"}
                        title="Costo de batería KW/h (USD)"
                        value={NumberFormat(state?.costKw ?? "", "es-MX")}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} mt={3}>
                <Accordion className="detailBattery" expanded={expanded === "panel1"} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<StyledIcon><IconArrow /></StyledIcon>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Typography>Ficha técnica</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="accordionContent">
                    <Box sx={{ mb: 2 }}>
                        {descriptionBattery && descriptionBattery.length > 0 && (
                            <>
                            <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: "10px", fontSize: "14px" }}>
                                Descripción de la batería:
                            </Typography>
                            <Editor
                                disabled={true}
                                importJSON={descriptionBattery ?? ""}
                            />
                            </>
                        )}

                        {descriptionWarranty && descriptionWarranty.length > 0 && (
                            <>
                            <Typography variant="h6" fontWeight="bold" sx={{ marginTop: "20px", marginBottom: "10px", fontSize: "14px" }}>
                                Descripción de la garantía:
                            </Typography>
                            <Editor
                                disabled={true}
                                importJSON={descriptionWarranty ?? ""}
                            />
                            </>
                        )}

                        {(!descriptionBattery || !descriptionWarranty) && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                            <CircularProgress />
                            </Box>
                        )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </StyledBattery>
    )
}