import { Grid, Button, IconButton, Menu, MenuItem, Fade } from "@mui/material"
import { SketchPicker } from "react-color";
import { useState } from "react";

export const ViewColorPicker = ({ icon, handleChangeColor }) => {
    const [color, setColor] = useState('lightblue');
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleChange = (newValue) => {
        setColor(newValue);
        handleChangeColor(newValue.hex);
        setAnchorEl(null);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container>
            <Grid item>
                <Button
                    className="color-text-mui"
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disableElevation
                >
                    <IconButton>
                        {icon}
                    </IconButton>
                </Button>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem>
                        <SketchPicker color={color} onChange={handleChange}/>
                    </MenuItem>
                </Menu>
            </Grid>
        </Grid>
    )
}